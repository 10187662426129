import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    useRecordContext,
    FieldProps,
    TopToolbar,
    Button,
    useUpdate,
    useGetIdentity,
    useRedirect,
    fetchUtils,
    useNotify,
    useRefresh,
    useSidebarState,
} from 'react-admin';
import { useEffect, useState } from 'react';
import { Patient } from '../../types';
import { format } from 'date-fns';
import dataProvider from '../../dataProvider';
import tokenManager from '../../tokenManager';
import { logActivity } from '../../logActivity.js'
import '../../scss/pages/HomeExercisePlan.scss';
import SessionTimeout from '../../SessionTimeout';
import { Accordion, AccordionSummary, Box, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Paper, Slide, Typography } from '@mui/material';
import ConditionAccordion from '../../components/patients/homeexerciseplan/ConditionAccordion';
import { CloseSharp, Delete } from '@mui/icons-material';
import CreateCustomHomeExercisePlanModal from '../../components/patients/homeexerciseplan/CreateCustomHomeExercisePlanForm';

const HomeExercisePlan = (props: FieldProps<Patient>) => {

    var date = Number(localStorage.getItem('testDate'))
    if (!date) {
        date = 0
    }
    
    const record = useRecordContext<Patient>();

    const [conditions, setConditions] = useState([])
    const [hepLoading, setHepLoading] = useState(true)
    const [bulkActionLoading, setBulkActionLoading] = useState(false)
    const [openStates, setOpenStates] = useState({})
    const [allOpen, setAllOpen] = useState(false)
    const [selectedExercises, setSelectedExercises] = useState<String[]>([])
    const [allExercisesSelected, setAllExercisesSelected] = useState({})
    const [createModalOpen, setCreateModalOpen] = useState(false)
    const [key, setKey] = useState(0)
    const [update, { isLoading }] = useUpdate();
    const { identity, isLoading: identityLoading } = useGetIdentity();
    const redirect = useRedirect()
    const notify = useNotify()
    const refresh = useRefresh()

    const [sidebarOpen] = useSidebarState()
    const [bannerStyle, setBannerStyle] = useState({ width: 'calc(100% - 280px)', left: '260px'})

    useEffect(() => {
        const calculateBannerWidth = () => {
            if (sidebarOpen) {
                setBannerStyle({
                  width: 'calc(100% - 280px)',
                  left: '260px'
                })
            } else {
                setBannerStyle({
                    width: `calc(100% - 90px)`,
                    left: `70px`
                })
            }
        }

        calculateBannerWidth()
    }, [sidebarOpen])

    var startTime = new Date();

    useEffect(() => {
        const fetchConditions = async () => {
          setHepLoading(true)
          const result = await dataProvider.getOne('hepDetails', {id: record.id})
          if (key === 0) {
            // initial load
            const initialOpenStates = {}
            result.data.userConditions.forEach((condition) => {
                initialOpenStates[condition.userCondition.id] = false
            })
            setOpenStates(initialOpenStates)
            setAllExercisesSelected(initialOpenStates)
          } else {
            const openStatesCopy = {...openStates}
            const allExercisesSelectedCopy = {...allExercisesSelected}
            result.data.userConditions.forEach((condition) => {
                // in case we get any new conditions after initial load
                if (openStatesCopy[condition.userCondition.id] !== true) {
                    openStatesCopy[condition.userCondition.id] = false
                }
                if (allExercisesSelectedCopy[condition.userCondition.id] !== true) {
                    allExercisesSelectedCopy[condition.userCondition.id] = false
                }
            })
            setOpenStates(openStatesCopy)
            setAllExercisesSelected(allExercisesSelectedCopy)
          }
          setConditions(result.data.userConditions)
          setHepLoading(false)
        }
        fetchConditions();
    }, [key])

    // add new activity log
    const updateWithNewRecord = async (newActivity) => {

        if (newActivity.duration <= 1) return

        var currRecord = record;
        if(identityLoading && !isLoading && tokenManager.getToken()) {
            await dataProvider.getOne(`users`, record).then(result => {
                currRecord = result.data;
            });
        }

        var currDate = currRecord.start_billing_date;
        var currTime = currRecord.start_billing_time;
        var currBillingDuration = currRecord.current_billing_duration;

        if( currDate == null || 
            currDate === "" ||
            currTime == null || 
            currTime === "" ) {
            currDate = format(new Date(), 'yyyy-MM-dd');
            currTime = format(new Date(), 'HH:mm');
        }
        
        if( currBillingDuration == null || 
            currBillingDuration === 0) {
            currBillingDuration = newActivity.duration
        } else {
            currBillingDuration = currRecord.current_billing_duration + newActivity.duration
        }

        // add to the beginning of array - the order of these activities are in the same order as displayed on activity log table ... bottom to top
        const diff = { 
            current_billing_duration: currBillingDuration,
            start_billing_date: currDate,
            start_billing_time: currTime,
        };

        update(
            'users',
            { id: currRecord.id, data: diff, previousData: currRecord }
        )

        logActivity('PROVIDER.View.HEP', newActivity.activity, newActivity.comment, currRecord.id, newActivity.duration, date)
    }

    // Add activity log when moving away from Home Exericse Plan
    useEffect(
        () => {
            return () => {
                const duration = Math.round((new Date().getTime() - startTime.getTime())/1000);
                var newActivity = {
                    "date": Math.round(Date.now()/1000),
                    "duration": duration,
                    "activity": "Viewed Home Exercise Plan",
                    "first": identity?.firstName ?? "",
                    "last": identity?.lastName ?? "",
                    "email": identity?.username ?? "", // TODO is email going to be used as username?
                    "picture": identity?.picture ?? "",
                    "comment": "None",
                    "billable": false // TODO check to see if the current viewing period is billable
                };
                updateWithNewRecord(newActivity);
            }
        },
        [!identityLoading]
    );

    const toggleAllOpen = () => {
        const openStatesCopy = {...openStates}
        Object.keys(openStatesCopy).forEach((condition) => {
            openStatesCopy[condition] = !allOpen
        })
        setAllOpen(!allOpen)
        setOpenStates(openStatesCopy)
    }

    const bulkUpdateStatus = (status: string) => {
        setBulkActionLoading(true)
        const apiUrl = (window.location.hostname === "localhost") ? 'http://localhost:1337' : '';
        const accessToken = tokenManager.getToken();

        const options = {}
        options['credentials'] = 'include'
        options['user'] = {
            authenticated: true,
            token: `Bearer ${accessToken}`,
        }

        const httpClient = fetchUtils.fetchJson;

        httpClient(`${apiUrl}/kaizenovate/provider/1.0.0/bulk-edit-exercise`, {
          method: 'POST',
          credentials: 'include',
          user: {
              authenticated: true,
              token: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({
            ids: selectedExercises,
            status: status
          }),
        }).then((result) => {
            if (result.json && result.json.length > 0) {
                setBulkActionLoading(false)
                setKey(Date.now())
            } else {
                console.error("Error in HomeExercisePlan:bulkUpdateStatus()")
                notify("We ran into an error while trying to update exercises. Please try again later", {type: 'error'})
                setBulkActionLoading(false)
                setKey(Date.now())
            }
        }).catch((e)=> {
            console.log(`Error in HomeExercisePlan:bulkUpdateStatus()`)
            console.error(e)
            setKey(Date.now())
            throw e
        })
    };
  
    const bulkDelete = () => {
        setBulkActionLoading(true)
        console.log("Deleting selected exercises:", selectedExercises);
        dataProvider.deleteMany('exercises', {ids: selectedExercises}).then((result) => {
            if (result.data && result.data.length > 0 && result.data[0].id) {
                const allSelectedCopy = {...allExercisesSelected}
                Object.keys(allSelectedCopy).forEach((condition) => {
                    allSelectedCopy[condition] = false
                })
                setAllExercisesSelected(allSelectedCopy)
                setSelectedExercises([])
                setBulkActionLoading(false)
                setKey(Date.now())
            } else {
                console.error("Error in HomeExercisePlan:bulkDelete()")
                notify("We ran into an error while trying to delete exercises. Please try again later", {type: 'error'})
                setBulkActionLoading(false)
                setKey(Date.now())
            }
        }).catch((e) => {
            console.error("Error in HomeExercisePlan:bulkDelete()")
            console.error(e)
            notify("We ran into an error while trying to delete exercises. Please try again later", {type: 'error'})
            setBulkActionLoading(false)
            setKey(Date.now())
        })
    };

    const clearSelection = () => {
        let newAllSelected = {}
        Object.keys(allExercisesSelected).forEach((condition) => {
            newAllSelected[condition] = false
        })
        setAllExercisesSelected(newAllSelected)
        setSelectedExercises([])
    }

    const CreateExerciseLink = () => {
        return <Button label="Create" onClick={() => setCreateModalOpen(true)}/>
    }

    const ListActions = ({ isSmall }: any) => (
        <TopToolbar sx={{ minHeight: { sm: 56 } }}>
            <CreateExerciseLink />
        </TopToolbar>
    );

    return (
        <div style={{paddingBottom: '50px', position: 'relative'}}>
            <SessionTimeout isAuthenticated={true} logOut={() => {redirect('/users')}}/>
            { (process.env.REACT_APP_ENV === "STAGING" || process.env.REACT_APP_ENV === "LOCAL") ? <DeleteLastHEPLogButton /> : null}

            <CreateCustomHomeExercisePlanModal setKey={setKey} open={createModalOpen} onClose={() => {setCreateModalOpen(false); refresh()}} />

            {hepLoading ? (
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress />
                </div>
            ) : (
                <div>
                    <div style={{ position: 'relative'}}>
                        <Slide
                            in={selectedExercises.length > 0}
                            direction="up"
                            mountOnEnter
                            unmountOnExit
                        >
                            <div
                                style={{
                                    position: 'fixed',
                                    top: '70px',
                                    zIndex: 100,
                                    backgroundColor: '#e3f2fd',
                                    ...bannerStyle
                                }}
                            >
                                <BulkActionsMenu
                                    selectedIds={selectedExercises}
                                    onClearSelection={clearSelection}
                                    bulkUpdateStatus={bulkUpdateStatus}
                                    bulkDelete={bulkDelete}
                                    isLoading={bulkActionLoading}
                                />
                            </div>
                        </Slide>

                        <div style={{ zIndex: 1 }}>
                            <ListActions />
                        </div>
                    </div>
                    <Grid container>
                        <div className='condition-list'>
                            <div style={{height: '0px'}}/>
                            <Accordion
                                className="condition-list-accordion"
                                expanded={false}
                            >
                                <AccordionSummary className="condition-list-header-container">
                                    <Grid className="condition-list-header" container alignItems="center">
                                        <Grid item xs={1}>
                                        <IconButton
                                            onClick={toggleAllOpen}
                                        >
                                            {allOpen ? <ExpandMoreIcon /> : <ExpandMoreIcon sx={{transform: 'rotate(-90deg)'}}/>}
                                        </IconButton>  
                                        </Grid>
                                        <Grid item xs={3}>
                                            <strong>Condition</strong>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <strong>HEP Type</strong>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <strong>Status</strong>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <strong style={{display: 'flex', justifyContent: 'center'}}>Actions</strong>
                                        </Grid>
                                    </Grid>
                                </AccordionSummary>
                            </Accordion>
                            {conditions.map((condition:any) => (
                                <ConditionAccordion
                                    key={condition.id}
                                    patientRecord={record}
                                    condition={condition}
                                    openStates={openStates}
                                    allOpen={allOpen}
                                    setOpenStates={setOpenStates}
                                    setAllOpen={setAllOpen}
                                    selectedExercises={selectedExercises}
                                    setSelectedExercises={setSelectedExercises}
                                    allExercisesSelected={allExercisesSelected}
                                    setAllExercisesSelected={setAllExercisesSelected}
                                    setKey={setKey}
                                />
                            ))}
                        </div>
                    </Grid>
                </div>
            )}
        </div>
    )
}

const BulkActionsMenu = ({ selectedIds, onClearSelection, bulkUpdateStatus, bulkDelete, isLoading }) => {

    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)

    return (
        <Paper
            elevation={3}
            sx={{
                width: '100%',
                position: 'sticky',
                top: 0,
                left: 0,
                right: 0,
                zIndex: 10,
                backgroundColor: '#e3f2fd',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: "8px 16px",
                boxShadow: 2,
            }}
        >
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <IconButton
                    onClick={onClearSelection}
                    disabled={isLoading}
                >
                    <CloseSharp />
                </IconButton>

                <Typography variant="subtitle1" color="primary">
                    {selectedIds.length} items selected
                </Typography>
            </div>

            {isLoading ? <CircularProgress color="inherit" disableShrink size="25px"/> : <Box>
                <Button
                    color="primary"
                    startIcon={<ThumbUpIcon />}
                    onClick={() => {
                        bulkUpdateStatus("active")
                    }}
                    disabled={isLoading}
                    label='ACTIVE'
                />
                <Button
                    color="primary"
                    startIcon={<ThumbDownIcon />}
                    onClick={() => {
                        bulkUpdateStatus("inactive")
                    }}
                    disabled={isLoading}
                    label='INACTIVE'
                />
                <Button
                    color="error"
                    startIcon={<Delete />}
                    onClick={() => {
                        setConfirmDialogOpen(true)
                    }}
                    disabled={isLoading}
                    label='DELETE'
                />
            </Box>}
            <Dialog
                open={confirmDialogOpen}
                onClose={() => setConfirmDialogOpen(false)}
            >
                <DialogTitle>
                    Delete Exercise
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete the selected exercises?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setConfirmDialogOpen(false)}
                        color="primary"
                        label="Cancel"
                        sx={{
                            fontSize: '1rem',
                        }}
                    />
                    <Button
                        onClick={() => {
                            bulkDelete()
                            setConfirmDialogOpen(false)
                        }}
                        color="error"
                        label="Delete"
                        sx={{
                            fontSize: '1rem',
                        }}
                    />
                </DialogActions>
            </Dialog>
        </Paper>
    )
}

const DeleteLastHEPLogButton = () => {

    const record = useRecordContext()
    const [isLoading, setIsLoading] = useState(false)
    const notify = useNotify()

    return (
        <Button disabled={isLoading} label="Delete Today's HEP Log" onClick={
            () => {
                setIsLoading(true)
                const apiUrl = (window.location.hostname === "localhost") ? 'http://localhost:1337' : '';
                const accessToken = tokenManager.getToken();

                const options = {}
                options['credentials'] = 'include'
                options['user'] = {
                    authenticated: true,
                    token: `Bearer ${accessToken}`,
                }

                const httpClient = fetchUtils.fetchJson;

                httpClient(`${apiUrl}/kaizenovate/provider/1.0.0/delete-today-hep-log`, {
                    method: 'DELETE',
                    credentials: 'include',
                    user: {
                        authenticated: true,
                        token: `Bearer ${accessToken}`,
                    },
                    body: JSON.stringify({
                        user_id: record.id,
                        practice_id: localStorage.getItem('selectedPractice')
                    }),
                }).then((result) => {
                  if (result.json.message === "OK") {
                      notify("Successfully deleted today's HEP log", {type: 'info'})
                  } else {
                      notify("Failed to delete today's HEP log", {type: 'error'})
                  }
                  setIsLoading(false)
                }).catch((e)=> {
                    console.log(`Error in Delete Today's HEP Log button`)
                    console.error(e)
                    notify("Failed to delete today's HEP log", {type: 'error'})
                    setIsLoading(false)
                    throw e
                })
            }
        }>

        </Button>
    )
}

export default HomeExercisePlan;
