import {
    FilterButton,
    ReferenceInput,
    SelectInput,
    TopToolbar,
    CreateButton,
    SearchInput,
    FilterForm,
    SortButton,
    ListBase,
    Title,
    useGetResourceLabel,
    FilterContext,
    Pagination,
    useRefresh,
} from 'react-admin';

import { useState } from 'react';
import { Box, Modal, Theme, Typography, useMediaQuery } from '@mui/material';
import { MasterprogramsEditModal } from './MasterprogramsEdit';
import { MasterprogramsCreateModal } from './MasterprogramsCreate';
import ImageList from '../../components/homeexercises/GridList';

const MasterprogramsList = ({ adminMode }) => {
    const getResourceLabel = useGetResourceLabel();
    const refresh = useRefresh()

    // const match = matchPath('/masterprograms/:id', location.pathname);

    // const [exerciseState, setExerciseState] = useState( location.state );

    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [createModeString, setCreateModeString] = useState("");
    const [selectedRecord, setSelectedRecord] = useState<any>(null);

    const handleCloseEditModal = () => {
        setEditModalOpen(false)
        setSelectedRecord(null)
    }

    const handleCloseCreateModal = () => {
        setCreateModalOpen(false)
        setCreateModeString("")
        setSelectedRecord(null)
    }

    const masterprogramsFilters = [
        <SearchInput source="name" alwaysOn />,
        <ReferenceInput
            source="tags"
            reference="tags"
            sort={{ field: 'name', order: 'ASC' }}
            filter={{ object_type: 'masterprograms', practice_id: adminMode ? "EMBODI" : localStorage.getItem('selectedPractice') }}
        >
            <SelectInput
                optionText="name"
                optionValue="id"
            />
        </ReferenceInput>,
        <SelectInput
            label="HEP Type"
            source="hep_type"
            choices={[
                { id: "progressive", name: "Progressive" },
                { id: "static", name: "Static" },
            ]}
        />,
        <SelectInput
            label="Created by"
            source="created_by"
            choices={[
                { id: "EMBODI", name: "EMBODI" },
                { id: localStorage.getItem('selectedPractice'), name: localStorage.getItem('selectedPracticeName') },
            ]}
        />,
    ];

    const ListActions = () => (
      <TopToolbar sx={{ minHeight: { sm: 56 } }}>
          <FilterForm />
          <FilterButton />
          <SortButton fields={['name']} />
          <CreateButton
              onClick={(event) => {
                  event.preventDefault()
                  setCreateModeString("Create")
                  setCreateModalOpen(true)
              }}
          />
      </TopToolbar>
    );

    return (
        <Box>
            {/* Edit Modal */}
            <Modal
                open={(editModalOpen && selectedRecord)}
                onClose={handleCloseEditModal}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: { xs: '100%', sm: '80%', md: '60%', lg: '50%' },
                        maxHeight: '90vh',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        overflowY: 'auto',
                    }}
                >
                    <Typography variant="h5" gutterBottom>
                        Edit Protocol
                    </Typography>
                    <MasterprogramsEditModal
                        record={selectedRecord}
                        onCancel={handleCloseEditModal}
                        onSuccess={() => {handleCloseEditModal(); refresh();}}
                        adminMode={adminMode}
                    />
                </Box>
            </Modal>

            {/* Create Modal */}
            <Modal
                open={createModalOpen}
                onClose={handleCloseCreateModal}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: { xs: '100%', sm: '80%', md: '60%', lg: '50%' },
                        maxHeight: '90vh',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        overflowY: 'auto',
                    }}
                >
                    <Typography variant="h5" gutterBottom>
                        {`${createModeString} Protocol`}
                    </Typography>
                    <MasterprogramsCreateModal
                        record={selectedRecord}
                        onCancel={handleCloseCreateModal}
                        onSuccess={() => {handleCloseCreateModal(); refresh();}}
                        adminMode={adminMode}
                        copyMode={(createModeString === "Copy & Customize") ? true : false}
                    />
                </Box>
            </Modal>



            <ListBase
                resource="masterprograms"
                perPage={12}
                filter={adminMode ? { practice_id: "EMBODI" } : { practice_id: { in: [localStorage.getItem('selectedPractice'), "EMBODI"] } }}
                sort={{ field: 'name', order: 'ASC' }}
            >
                <Title defaultTitle={getResourceLabel('masterprograms', 2)} />
                <FilterContext.Provider value={masterprogramsFilters}>
                    <ListActions />
                </FilterContext.Provider>
                <Box display="flex">
                    <Box width={isSmall ? 'auto' : '100%'} mr={2}>
                        <ImageList
                            setEditModalOpen={setEditModalOpen}
                            setCreateModalOpen={setCreateModalOpen}
                            setSelectedRecord={setSelectedRecord}
                            setCreateModeString={setCreateModeString}
                            adminMode={adminMode}
                        />
                        <Pagination rowsPerPageOptions={[12, 24, 48, 72]}/>
                    </Box>
                </Box>
            </ListBase>
        </Box>
    );
};

export default MasterprogramsList;
