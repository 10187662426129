import * as React from 'react';
import { Fragment } from 'react';
import {
    SimpleForm,
    Toolbar,
    NumberInput,
    TextInput,
    ArrayInput,
    SimpleFormIterator,
    ReferenceInput,
    SelectInput,
    SaveButton,
    Edit,
    Button,
    required,
    useNotify,
    FormDataConsumer,
} from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';
import { Box, Grid } from '@mui/material';

import '../../scss/pages/Exerciselibrarys.scss';
import '../../scss/pages/Layouts.scss';
import TagListField from '../../components/homeexercises/TagListField';

export const ExerciselibrarysEditModal = ({ record, onCancel, onSuccess, adminMode }) => {
    return <ExerciselibrarysEditForm record={record} onCancel={onCancel} onSuccess={onSuccess} adminMode={adminMode} />;
}

const ExerciselibrarysEditForm = ({ record, onCancel, onSuccess, adminMode }) => {

    const notify = useNotify();

    const getYouTubeVideoId = (url) => {
        try {
            const parsedUrl = new URL(url)
            const hostname = parsedUrl.hostname
            const isYouTubeDomain = hostname === 'www.youtube.com' || hostname === 'youtube.com' || hostname === 'youtu.be'

            if (!isYouTubeDomain) return false;

            if (hostname === 'youtu.be') {
                // Shortened URL format (e.g., https://youtu.be/dQw4w9WgXcQ)
                if (parsedUrl.pathname.length === 12) { // Video ID is 11 characters long
                    return parsedUrl.pathname.slice(1)
                } else {
                    return false
                }
            }

            if (hostname.includes('youtube.com')) {
                // Full YouTube URL format (e.g., https://www.youtube.com/watch?v=dQw4w9WgXcQ)
                const videoId = parsedUrl.searchParams.get('v');
                if (videoId && videoId.length === 11) { // Video ID is 11 characters long
                    return videoId
                } else {
                    return false
                }
            }

            return false;
        } catch (e) {
            console.error("Error validating youtube video link")
            console.error(e)
            return false
        }
    }

    const transform = (data) => {
        if (!adminMode) {
            let newData = {...data}
            if (record.video !== newData.video) {
                const videoId = getYouTubeVideoId(newData.video)
                if (!videoId) {
                    notify("Custom videos must be valid YouTube video links", {type: 'error'})
                    throw new Error("Custom videos must be valid YouTube video links")
                }

                newData.thumbnail = `https://i.ytimg.com/vi/${videoId}/maxresdefault.jpg`
            }

            return newData
        }

        return data
    }
    
    const ExerciselibrarysEditToolbar = (  ) => {
    
        return (
            <Toolbar
                sx={{
                    backgroundColor: 'background.paper',
                    display: 'flex',
                    justifyContent: 'space-between',
                    minHeight: { sm: 0 },
                    marginBottom: "2em",
                }}
            >
                <Fragment>
                    <>
                        <Button onClick={onCancel} size='medium' variant="contained" label="Cancel" color="inherit" />
                        {/* <IconButton onClick={onCancel}>
                            <CloseIcon />
                        </IconButton> */}
                        <SaveButton icon={<></>}/>
                        {/* <DeleteButton /> */}
                    </>
                </Fragment>
            </Toolbar>
        );
    };

    return (
        <Edit
            resource="exerciselibrarys"
            className="exerciselibrary-edit"
            sx={{ maxWidth: '1020px' }}
            mutationMode='pessimistic'
            id={record.id}
            mutationOptions={{ onSuccess: onSuccess }}
            transform={transform}
        >
            <Box pt={2} sx={{ width: '100%' }} mt={{ xs: 2, sm: 1 }}>
                <SimpleForm
                    sx={{ pt: 0, pb: 0 }}
                    toolbar={
                        <ExerciselibrarysEditToolbar />
                    }
                    record={record}
                >
                    <Grid container rowSpacing={1} mb={1} columnSpacing={1}>
                        <Grid item xs={8}>
                            <TextInput source='name' isRequired validate={required()} fullWidth />
                        </Grid>
                        <Grid item xs={4}>
                            <SelectInput 
                                isRequired
                                validate={required()}
                                fullWidth
                                source="exercise_type"
                                emptyText={ "Exercise" } // Default values
                                emptyValue={ 'exercise' } // Default values
                                choices={[
                                    { id: 'exercise', name: 'Exercise' },
                                    { id: 'stretch', name: 'Stretch' },
                                ]} />
                        </Grid>
                    </Grid>
                    <Grid container rowSpacing={1} mb={1} columnSpacing={1}>
                        <Grid item xs={8}>
                            <TextInput source='video' isRequired validate={required()} fullWidth label='Video URL'/>
                        </Grid>
                        <Grid item xs={4}>
                            <FormDataConsumer>
                                {({ formData, ...rest }) =>
                                    <Button
                                        variant='text'
                                        onClick={() => {
                                            if (formData && formData.video) {
                                                window.open(formData.video, '_blank')
                                            } else {
                                                notify("No video link provided", { type: 'warning' })
                                            }
                                        }}
                                        label='Preview Video'
                                        {...rest}
                                    />
                                }
                            </FormDataConsumer>
                        </Grid>
                    </Grid>
                    {adminMode ? (
                        <Grid container rowSpacing={1} mb={1}>
                            <Grid item xs={12}>
                                <TextInput source='thumbnail' isRequired validate={required()} fullWidth label='Thumbnail URL'/>
                            </Grid>
                        </Grid>
                    ) : (null)}
                    <Grid container rowSpacing={1} mb={1}  columnSpacing={1}>
                        <Grid item xs={3}>
                            <NumberInput source='sets' isRequired validate={required()} defaultValue={3} fullWidth/>
                        </Grid>
                        <Grid item xs={3}>
                            <NumberInput source='rest' isRequired validate={required()} defaultValue={30} fullWidth/>
                        </Grid>
                        <Grid item xs={3}>
                            <NumberInput source='reps' defaultValue={10} fullWidth/>
                        </Grid>
                        <Grid item xs={3}>
                            <NumberInput source='hold' defaultValue={30} fullWidth/>
                        </Grid>
                    </Grid>
                    <Grid container rowSpacing={1} mb={1} columnSpacing={1}>
                        <Grid item xs={6}>
                            <ArrayInput source="muscle_groups" isRequired validate={required()} label="Regions">
                                <SimpleFormIterator>
                                    <ReferenceInput
                                        source=""
                                        reference="musclegroups"
                                        sort={{ field: 'name', order: 'ASC' }}
                                    >
                                        <SelectInput
                                            isRequired
                                            validate={required()}
                                            optionValue="name"
                                            optionText="name"
                                            fullWidth
                                        />
                                    </ReferenceInput>
                                </SimpleFormIterator>
                            </ArrayInput>
                        </Grid>
                        <Grid item xs={6}>
                            <TagListField
                                source="tags"
                                sourceType="exerciselibrarys"
                                label="Tags"
                                reference="tags"
                                adminMode={adminMode}
                            />
                        </Grid>
                    </Grid>
                </SimpleForm>
            </Box>
        </Edit>
    );
};
