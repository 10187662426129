import { useState } from 'react';
import { Tab, Tabs, Box } from '@mui/material';
import ExerciselibrarysList from '../exerciselibrarys/ExerciselibrarysList';
import MasterprogramsList from '../masterprograms/MasterprogramsList';

const HomeExercises = (props) => {
    const [activeTab, setActiveTab] = useState(0);

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    return (
        <Box>
            {/* Tabs for switching between lists */}
            <Tabs value={activeTab} onChange={handleTabChange} aria-label="Resource tabs">
                <Tab label="Exercise Library" />
                <Tab label="Protocol List" />
            </Tabs>

            {activeTab === 0 && (
                <Box>
                    <ExerciselibrarysList adminMode={false} {...props}/>
                </Box>
            )}

            {activeTab === 1 && (
                <Box>
                    <MasterprogramsList adminMode={false} {...props}/>
                </Box>
            )}
        </Box>
    );
};

export default HomeExercises;
