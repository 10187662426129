import { Box, Modal, Typography } from "@mui/material"
import ExerciseEdit from "../../../pages/exercises/ExerciseEdit";

const EditProgressiveExerciseModal = ({ open, onClose, setKey, record, setEditRecord }) => {
    return (
        <Modal
            open={open}
            onClose={onClose}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: { xs: '100%', sm: '80%', md: '60%', lg: '50%' },
                    maxHeight: '90vh',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    overflowY: 'auto',
                }}
            >
                <Typography variant="h5" gutterBottom>
                    {`Edit Exercise`}
                </Typography>
                <ExerciseEdit
                    onCancel={onClose}
                    onSuccess={() => {onClose(); setEditRecord(null); setKey(Date.now());}}
                    record={record}
                />
            </Box>
        </Modal>
    )
}

export default EditProgressiveExerciseModal;
