import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { FaCheck, FaTimes, FaEdit, FaCopy, FaTimesCircle } from 'react-icons/fa';
import {
  Button,
    ReferenceField,
    TextField,
    fetchUtils,
    useNotify,
    useRecordContext,
    useRefresh,
} from 'react-admin';
import tokenManager from '../../../tokenManager';
import ExerciseRefField from '../../../pages/exercises/ExerciseRefField';
import '../../../scss/pages/HomeExercisePlan.scss';
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, IconButton, LinearProgress, Menu, MenuItem, Modal, Select, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import EditCustomHomeExercisePlanModal from './EditCustomHomeExercisePlanModal';
import AddStaticExerciseModal from './AddStaticExerciseModal';
import EditStaticExerciseModal from './EditStaticExerciseModal';
import dataProvider from '../../../dataProvider';
import CreateProgressiveExerciseModal from './CreateProgressiveExerciseModal';
import EditProgressiveExerciseModal from './EditProgressiveExerciseModal';

const ConditionAccordion = ({
    patientRecord,
    condition,
    openStates,
    allOpen,
    setOpenStates,
    setAllOpen,
    selectedExercises,
    setSelectedExercises,
    allExercisesSelected,
    setAllExercisesSelected,
    setKey
}) => {

    const [anchorEl, setAnchorEl] = useState(null)
    const [conditionApprovalStatus, setConditionApprovalStatus] = useState(condition.userCondition.approval_status)
    const [approvalStatusLoading, setApprovalStatusLoading] = useState(false)
    const [deleteConfirmDialogOpen, setDeleteConfirmDialogOpen] = useState(false)
    const [deleteCustomHEPLoading, setDeleteCustomHEPLoading] = useState(false)
    const [editCustomHEPOpen, setEditCustomHEPOpen] = useState(false)
    const [createStaticExerciseModalOpen, setCreateStaticExerciseModalOpen] = useState(false)
    const [editStaticExerciseModalOpen, setEditStaticExerciseModalOpen] = useState(false)
    const [editRecord, setEditRecord] = useState(null)
    const [addAfterExercise, setAddAfterExercise] = useState(null)
    const [copyHepDetailsLoading, setCopyHepDetailsLoading] = useState(false)
    const [copyHepDetailsLoadingValue, setCopyHepDetailsLoadingValue] = useState(0)
    const [createProgressiveExerciseModalOpen, setCreateProgressiveExerciseModalOpen] = useState(false)
    const [editProgressiveExerciseModalOpen, setEditProgressiveExerciseModalOpen] = useState(false)
    const notify = useNotify();
    const refresh = useRefresh();

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleMenuClose = () => {
        setAnchorEl(null)
    }

    const generateExerciseClassname = (exercise, difficulty) => {
        let className = 'exercise-name'
        if (!exercise.has_active_hep) {
            return className
        }

        if (exercise.status === 'inProgress' && exercise.current_difficulty === difficulty) {
            className += ' exercise-up-next'
        }

        switch (difficulty) {
            case 'easy':
                if (exercise.easier_exercise?.reported_pain) {
                    className += ' exercise-increased-symptoms'
                }
                if (exercise.easier_exercise?.completed) {
                    className += ' exercise-completed'
                }
                if (exercise.easier_exercise?.too_hard) {
                    className += ' exercise-too-hard'
                }
                if (exercise.easier_exercise?.skipped) {
                    className += ' exercise-skipped'
                }
                break;
            case 'default':
                if (exercise.default_exercise?.reported_pain) {
                    className += ' exercise-increased-symptoms'
                }
                if (exercise.default_exercise?.completed || exercise.status === 'completed') {
                    className += ' exercise-completed'
                }
                if (exercise.default_exercise?.too_hard) {
                    className += ' exercise-too-hard'
                }
                if (exercise.default_exercise?.skipped) {
                    className += ' exercise-skipped'
                }
                break;
            case 'hard':
                if (exercise.harder_exercise?.reported_pain) {
                    className += ' exercise-increased-symptoms'
                }
                if (exercise.harder_exercise?.completed || exercise.status == 'completed') {
                    className += ' exercise-completed'
                }
                if (exercise.harder_exercise?.too_hard) {
                    className += ' exercise-too-hard'
                }
                if (exercise.harder_exercise?.skipped) {
                    className += ' exercise-skipped'
                }
                break;
        }


        return className
    }

    const copyToClipboard = async () => {
      setCopyHepDetailsLoading(true)
        let copyString = `Condition: ${condition.userCondition.name}\n\n`
        let count = 0
        for (const exercise of condition.exercises) {
            if (exercise.status === 'inactive') {
                setCopyHepDetailsLoadingValue((count++/condition.exercises.length) * 100)
                continue
            }

            copyString += `${exercise.sequence}. ${exercise.level.charAt(0).toUpperCase()}${exercise.level.slice(1)}: `
            if (exercise.easier_exercise) {
                const ex = await dataProvider.getOne('exerciselibrarys', {id: exercise.easier_exercise.exercise})
                copyString += `${ex.data.name} (easier), `
            }
            if (exercise.default_exercise) {
                const ex = await dataProvider.getOne('exerciselibrarys', {id: exercise.default_exercise.exercise})
                copyString += `${ex.data.name} (default)`
            }
            if (exercise.harder_exercise) {
                const ex = await dataProvider.getOne('exerciselibrarys', {id: exercise.harder_exercise.exercise})
                copyString += `, ${ex.data.name} (harder)`
            }
            copyString += '\n'
            setCopyHepDetailsLoadingValue((count++/condition.exercises.length) * 100)
        }
        navigator.clipboard.writeText(copyString)
        setCopyHepDetailsLoading(false)
        setCopyHepDetailsLoadingValue(0)
        handleMenuClose()
    }

    const deleteCustomHEP = async (userConditionId) => {
      setDeleteCustomHEPLoading(true)
    
      const apiUrl = (window.location.hostname === "localhost") ? 'http://localhost:1337' : '';
      const httpClient = fetchUtils.fetchJson;
      const accessToken = tokenManager.getToken();

      httpClient(`${apiUrl}/kaizenovate/provider/1.0.0/custom-hep/delete`, {
          method: 'DELETE',
          credentials: 'include',
          user: {
              authenticated: true,
              token: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({id: userConditionId}),
      }).catch((e)=> {
          console.log(`Error in delete custom HEP`)
          console.error(e)
          notify('Error: Could not delete custom HEP', {type: 'error'})
          setDeleteCustomHEPLoading(false)
          throw e
      }).then((response) => {
          if (response.json.error) {
              console.error(response.json.error)
              notify('Error: Could not delete custom HEP', {type: 'error'})
              setDeleteCustomHEPLoading(false)
              throw new Error(response.json.error)
          } else {
              notify('Custom HEP deleted')
              setDeleteCustomHEPLoading(false)
              setDeleteConfirmDialogOpen(false)
              refresh()
              setKey(Date.now())
          }
      })
  }

    const toggleExerciseSelection = (exerciseId: string, condition: any) => {
        if (selectedExercises.includes(exerciseId)) {
            setSelectedExercises(selectedExercises.filter((id) => id !== exerciseId))
            if (allExercisesSelected[condition.userCondition.id]) {
                const allSelectedCopy = {...allExercisesSelected}
                allSelectedCopy[condition.userCondition.id] = false
                setAllExercisesSelected(allSelectedCopy)
            }
        } else {
            setSelectedExercises([...selectedExercises, exerciseId])
            if (!allExercisesSelected[condition.userCondition.id]) {
                // check if all exercises for the given condition are selected
                const conditionExercises = condition.exercises.map((exercise) => exercise.id)
                if (conditionExercises.every((exercise) => [...selectedExercises, exerciseId].includes(exercise))) {
                    const allSelectedCopy = {...allExercisesSelected}
                    allSelectedCopy[condition.userCondition.id] = true
                    setAllExercisesSelected(allSelectedCopy)
                }
            }
        }
    }

    const changeApprovalStatus = async (event) => {
        setApprovalStatusLoading(true)

        const apiUrl = (window.location.hostname === "localhost") ? 'http://localhost:1337' : '';
        const httpClient = fetchUtils.fetchJson;
        const accessToken = tokenManager.getToken();

        try {
            const result = await httpClient(`${apiUrl}/kaizenovate/provider/1.0.0/resolve_hep`, {
                method: 'POST',
                credentials: 'include',
                user: {
                    authenticated: true,
                    token: `Bearer ${accessToken}`,
                },
                body: JSON.stringify({
                    patient_id: condition.userCondition.user,
                    condition_id: condition.userCondition.condition_id,
                    denied: event.target.value === 'denied' ? true : false
                }),
            })

            if (result.json.message === 'success') {
                setConditionApprovalStatus(event.target.value)
                setApprovalStatusLoading(false)
                setKey(Date.now())
                refresh()
                
            } else {
                console.error(result.json.error)
                setApprovalStatusLoading(false)
                setKey(Date.now())
                refresh()
                notify("Sorry, we ran into an error while trying to update approval status. Please try again later", {type: 'error'})
            }
        } catch (e) {
            console.error(e)
            setApprovalStatusLoading(false)
            setKey(Date.now())
            refresh()
            notify("Sorry, we ran into an error while trying to update approval status. Please try again later", {type: 'error'})
        }
    }

    const TooltipTextField = ({ source, className }) => {
        const record = useRecordContext()
        const textRef = useRef(null)
        const [isOverflowing, setIsOverflowing] = useState(false)

        useEffect(() => {
            if (textRef.current) {
                const { offsetWidth, scrollWidth } = textRef.current
                setIsOverflowing(scrollWidth > offsetWidth)
            }
        }, [record])

        if (!record) return null

        return (
            <Tooltip title={isOverflowing ? record[source] : ""} >
                <span
                    ref={textRef}
                    style={{
                        display: 'block',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                >
                    <TextField source={source} className={className} />
                </span>
            </Tooltip>
        )
    }

    return (
        <Accordion
            className="condition-list-accordion"
            key={condition.userCondition.id}
            expanded={openStates[condition.userCondition.id]}
        >
            <AccordionSummary className="condition-list-item-container">
                <Grid className="condition-list-item" container alignItems="center">
                    <Grid item xs={1}>
                        <Modal
                            open={deleteConfirmDialogOpen}
                            onClose={() => setDeleteConfirmDialogOpen(false)}
                        >
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    width: 400,
                                    bgcolor: 'background.paper',
                                    boxShadow: 24,
                                    p: 4,
                                }}
                            >
                                <div className="delete-confirm-dialog">
                                    <h2>Delete Custom HEP</h2>
                                    <p>Are you sure you want to delete this custom HEP?</p>
                                    <div
                                        className="delete-confirm-dialog-buttons"
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            marginTop: '20px'
                                        }}
                                    >
                                        <Button
                                            className="delete-confirm-dialog-button"
                                            onClick={() => setDeleteConfirmDialogOpen(false)}
                                            disabled={deleteCustomHEPLoading}
                                            label="Cancel"
                                            variant="contained"
                                        />
                                        <Button
                                            className="delete-confirm-dialog-button"
                                            onClick={() => {deleteCustomHEP(condition.userCondition.id)}}
                                            disabled={deleteCustomHEPLoading}
                                            label="Delete"
                                            variant='contained'
                                            color='error'
                                        />
                                    </div>
                                </div>
                            </Box>
                        </Modal>
                        <CreateProgressiveExerciseModal
                            open={createProgressiveExerciseModalOpen && addAfterExercise}
                            onClose={() => {
                                setCreateProgressiveExerciseModalOpen(false)
                                setAddAfterExercise(null)
                            }}
                            setKey={setKey}
                            condition_id={condition.userCondition.condition_id}
                            user_id={patientRecord.id}
                            addAfterExercise={addAfterExercise}
                        />
                        <EditProgressiveExerciseModal
                            open={editProgressiveExerciseModalOpen}
                            onClose={() => setEditProgressiveExerciseModalOpen(false)}
                            setKey={setKey}
                            record={editRecord}
                            setEditRecord={setEditRecord}
                        />
                        <EditStaticExerciseModal
                            record={editRecord}
                            open={editStaticExerciseModalOpen}
                            onClose={() => {setEditStaticExerciseModalOpen(false); setEditRecord(null);}}
                            setKey={setKey}
                        />
                        <AddStaticExerciseModal
                            condition={condition}
                            open={createStaticExerciseModalOpen}
                            onClose={() => {
                                setCreateStaticExerciseModalOpen(false)
                                setAddAfterExercise(null)
                            }}
                            setKey={setKey}
                            addAfterExercise={addAfterExercise}
                        />
                        <EditCustomHomeExercisePlanModal
                            record={condition.userCondition}
                            open={editCustomHEPOpen}
                            onClose={() => setEditCustomHEPOpen(false)}
                            setKey={setKey}
                        />
                        <IconButton
                            className="condition-list-expand-icon"
                            onClick={() => {
                                const openStatesCopy = {...openStates}
                                openStatesCopy[condition.userCondition.id] = !openStates[condition.userCondition.id]
                                const selectedExercisesCopy = selectedExercises.filter((exerciseId) => {
                                    return !(condition.exercises.find((exercise) => exercise.id === exerciseId))
                                })
                                setSelectedExercises(selectedExercisesCopy)
                                setOpenStates(openStatesCopy)
                                if (!openStatesCopy[condition.userCondition.id] && allExercisesSelected[condition.userCondition.id]) {
                                    const allSelectedCopy = {...allExercisesSelected}
                                    allSelectedCopy[condition.userCondition.id] = false
                                    setAllExercisesSelected(allSelectedCopy)
                                }
                                if (openStatesCopy[condition.userCondition.id] && !allOpen) {
                                    setAllOpen(true)
                                } else if (!openStatesCopy[condition.userCondition.id] && allOpen) {
                                    const openValues = Object.values(openStatesCopy)
                                    if (!openValues.includes(true)) {
                                        setAllOpen(false)
                                    }
                                }
                            }}
                        >
                            {openStates[condition.userCondition.id] ? <ExpandMoreIcon /> : <ExpandMoreIcon sx={{transform: 'rotate(-90deg)'}}/>}
                        </IconButton>
                    </Grid>
                    <Grid className="condition-name" item xs={3}>{condition.userCondition.name}</Grid>
                    <Grid className="condition-hep-type" item xs={4}>{condition.userCondition.hep_type.charAt(0).toUpperCase() + condition.userCondition.hep_type.slice(1)}</Grid>
                    <Grid className="condition-status" item xs={3}>
                        <Select
                            className={`condition-approval-status-select ${conditionApprovalStatus}`}
                            value={conditionApprovalStatus}
                            onChange={changeApprovalStatus}
                            disabled={approvalStatusLoading}
                            renderValue={(selected) => {
                                switch (selected) {
                                    case 'denied':
                                        return 'HEP Denied'
                                    case 'approved':
                                        return 'HEP Approved'
                                    case 'pending':
                                        return 'HEP Pending'
                                }
                            }}
                        >
                            <MenuItem value={'approved'}>Approve HEP</MenuItem>
                            <MenuItem value={'denied'}>Deny HEP</MenuItem>
                            <MenuItem style={{display: 'none'}} value={'pending'}>Pending HEP</MenuItem>
                        </Select>
                    </Grid>
                    <Grid className="condition-actions" item xs={1}>
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <>
                                <IconButton onClick={handleMenuClick}>
                                    <MoreVertIcon />
                                </IconButton>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={handleMenuClose}
                                >
                                    {condition.userCondition.custom_hep && (
                                        <MenuItem onClick={() => setEditCustomHEPOpen(true)}>
                                            <FaEdit style={{ marginRight: 10 }}/>
                                            <span>Edit Settings</span>
                                        </MenuItem>
                                    )}
                                    <MenuItem onClick={copyToClipboard} disabled={copyHepDetailsLoading}>
                                        <FaCopy style={{ marginRight: 10 }}/>
                                        <span>Copy to Clipboard</span>
                                    </MenuItem>
                                    {copyHepDetailsLoading && (
                                        <MenuItem>
                                            <div style={{ width: '100%' }}>
                                                <LinearProgress variant="determinate" value={copyHepDetailsLoadingValue} />
                                            </div>
                                        </MenuItem>
                                    )}
                                    {condition.userCondition.custom_hep && (
                                        <MenuItem onClick={() => setDeleteConfirmDialogOpen(true)}>
                                            <FaTimesCircle style={{ marginRight: 10, color: 'red' }}/>
                                            <span style={{ color: 'red' }}>Delete</span>
                                        </MenuItem>
                                    )}
                                </Menu>
                            </>
                        </div>
                    </Grid>
                </Grid>
            </AccordionSummary>
            {condition.exercises.length > 0 ? (
                <AccordionDetails className="exercise-list-container">
                    <Table className="exercise-list">
                        <TableHead className="exercise-list-header">
                            <TableRow className="exercise-list-header-row">
                                <TableCell className="exercise-list-cell" style={{ width: '0' }}></TableCell>
                                <TableCell className="exercise-list-cell" >
                                    <input
                                        className="exercise-list-header-checkbox"
                                        type="checkbox"
                                        checked={allExercisesSelected[condition.userCondition.id]}
                                        onChange={() => {
                                            const allSelectedCopy = {...allExercisesSelected}
                                            allSelectedCopy[condition.userCondition.id] = !allExercisesSelected[condition.userCondition.id]
                                            setAllExercisesSelected(allSelectedCopy)
                                            const selectedExercisesCopy = selectedExercises.filter((exerciseId) => {
                                                return !(condition.exercises.find((exercise) => exercise.id === exerciseId))
                                            })
                                            if (allExercisesSelected[condition.userCondition.id]) {
                                                setSelectedExercises(selectedExercisesCopy)
                                            } else {
                                                setSelectedExercises([...selectedExercisesCopy, ...condition.exercises.map((exercise) => exercise.id)])
                                            }
                                        }}
                                    />
                                </TableCell>
                                <TableCell className="exercise-list-cell" >Edit</TableCell>
                                <TableCell className="exercise-list-cell" >Add</TableCell>
                                <TableCell className="exercise-list-cell" >Level</TableCell>
                                <TableCell className="exercise-list-cell" >Easier</TableCell>
                                <TableCell className="exercise-list-cell" >Starting</TableCell>
                                <TableCell className="exercise-list-cell" >Harder</TableCell>
                                <TableCell className="exercise-list-cell" style={{ width: '8%' }}>
                                    <div style={{display: 'flex', justifyContent: 'center'}} >Enabled?</div>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {condition.exercises.map((exercise:any) => (
                                <TableRow className={`exercise-list-item${exercise.status === 'inactive' ? ' exercise-inactive' : ''}`} key={exercise.id}>
                                    <TableCell className="exercise-list-cell" style={{ width: '0' }}></TableCell>
                                    <TableCell className="exercise-list-cell" >
                                        <input
                                            className="exercise-list-item-checkbox"
                                            type="checkbox"
                                            checked={selectedExercises.includes(exercise.id)}
                                            onChange={() => toggleExerciseSelection(exercise.id, condition)}
                                        />
                                    </TableCell>
                                    <TableCell className="exercise-list-cell" >
                                        <ExerciseRefField
                                            record={exercise}
                                            isStaticExercise={condition.userCondition.hep_type === 'static'}
                                            setEditStaticOpenState={setEditStaticExerciseModalOpen}
                                            setEditProgressiveOpenState={setEditProgressiveExerciseModalOpen}
                                            setEditRecord={setEditRecord}
                                        />
                                    </TableCell>
                                    <TableCell className="exercise-list-cell" >
                                        <Button
                                            label={`Add After`}
                                            onClick={() => {
                                                setAddAfterExercise(exercise)
                                                if (condition.userCondition.hep_type === 'static') {
                                                    setCreateStaticExerciseModalOpen(true)
                                                } else {
                                                    setCreateProgressiveExerciseModalOpen(true)
                                                }
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell className="exercise-list-cell" >{exercise.display_level}</TableCell>
                                    <TableCell className="exercise-list-cell" >
                                        <ReferenceField
                                            source="easier_exercise.exercise"
                                            reference="exerciselibrarys"
                                            record={exercise}
                                            link={false}
                                        >
                                            <TooltipTextField source="name" className={generateExerciseClassname(exercise, 'easy')} />
                                        </ReferenceField>
                                    </TableCell>
                                    <TableCell className="exercise-list-cell" >
                                        <ReferenceField
                                            source="default_exercise.exercise"
                                            reference="exerciselibrarys"
                                            record={exercise}
                                            link={false}
                                        >
                                            <TooltipTextField source="name" className={generateExerciseClassname(exercise, 'default')} />
                                        </ReferenceField>
                                    </TableCell>
                                    <TableCell className="exercise-list-cell" >
                                        <ReferenceField
                                            source="harder_exercise.exercise"
                                            reference="exerciselibrarys"
                                            record={exercise}
                                            link={false}
                                        >
                                            <TooltipTextField source="name" className={generateExerciseClassname(exercise, 'hard')} />
                                        </ReferenceField>
                                    </TableCell>
                                    <TableCell className="exercise-list-cell" style={{ width: '8%', padding: '8px' }}>
                                        <div style={{display: 'flex', justifyContent: 'center'}} >
                                            {exercise.status === 'inactive' ? <FaTimes className="exercise-list-cell-icon"/> : <FaCheck className="exercise-list-cell-icon"/>}
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                            <TableRow className="exercise-list-key" >
                                <TableCell align="center" colSpan={9}>
                                    <span className="exercise-list-key-text">KEY</span>
                                    <span className="exercise-list-key-text">{" | "}</span>
                                    <span className="exercise-too-hard">Too Hard</span>
                                    <span className="exercise-list-key-text">{" | "}</span>
                                    <span className="exercise-increased-symptoms">Reported Increased Symptoms</span>
                                    <span className="exercise-list-key-text">{" | "}</span>
                                    <span className="exercise-completed">Completed</span>
                                    <span className="exercise-list-key-text">{" | "}</span>
                                    <span className="exercise-up-next">Up Next</span>
                                    <span className="exercise-list-key-text">{" | "}</span>
                                    <span className="exercise-skipped">Skipped</span>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </AccordionDetails>
              ) : (
                <AccordionDetails className="exercise-list-container">
                    <Table className="exercise-list">
                        <TableBody>
                            <TableRow>
                                <TableCell align="left" colSpan={9}>
                                    <span style={{fontWeight: 'bold'}}>No exercises found for this condition</span>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </AccordionDetails>
            )}
        </Accordion>
    );
}

export default ConditionAccordion;