import { Box, Grid, Typography } from '@mui/material';
import { 
    Button,
    Edit,
    NumberInput,
    SelectInput,
    TextInput,
    SimpleForm,
    ReferenceInput,
    useEditContext,
    Toolbar,
    SaveButton,
} from 'react-admin';

const CustomToolbar = ( onCancel ) => {
  return <Toolbar style={{display: "flex", justifyContent:"space-between", alignItems: "center"}}>
      <Button
          label='Cancel'
          size='medium'
          variant='contained'
          onClick={onCancel.onCancel}
      />
      <SaveButton />
  </Toolbar>
}

const ExerciseEdit = ({ onCancel, onSuccess, record }) => {

    return (
        <Edit 
            resource="exercises"
            mutationMode="pessimistic"
            mutationOptions={{ onSuccess: onSuccess }}
            id={record.id}
        >
            <SimpleForm 
                // {...props}
                // validate={validateForm}
                record={record}
                toolbar={<CustomToolbar onCancel={onCancel} />}
            >
                <Box pt={2} sx={{ width: '100%' }} mt={{ xs: 2, sm: 1 }}>
                    <Grid container width={{ xs: '100%', xl: '80%' }} spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h4" gutterBottom mb={3}>
                                <Box fontWeight='fontWeightBold'>
                                    Details
                                </Box>
                            </Typography>
                            <Box display={{ xs: 'block', sm: 'flex' }}>
                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                    <SelectInput 
                                    isRequired
                                    fullWidth
                                    source="level" 
                                    emptyText={ "Relieve" } // Default values
                                    emptyValue={ 'relieve' } // Default values
                                    choices={[
                                        { id: 'relieve', name: 'Relieve' },
                                        { id: 'restore', name: 'Restore' },
                                        { id: 'reinforce', name: 'Reinforce' },
                                    ]} />
                                </Box>
                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                    <SelectInput 
                                    isRequired
                                    fullWidth
                                    source="display_level" 
                                    emptyText={ "1 - Relieve" } // Default values
                                    emptyValue={ '1 - Relieve' } // Default values
                                    choices={[
                                        { id: '1 - Relieve', name: '1 - Relieve' },
                                        { id: '2 - Restore', name: '2 - Restore' },
                                        { id: '3 - Reinforce', name: '3 - Reinforce' },
                                    ]} />
                                </Box>
                                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                                    <ReferenceInput
                                        source="condition_id"
                                        reference="conditions"
                                        filter={{ custom_hep: false }}
                                        sort={{ field: 'name', order: 'ASC' }}
                                    >
                                        <SelectInput 
                                            optionValue="id"
                                            optionText="name"
                                            source="id" 
                                            isRequired
                                            fullWidth
                                            label="Condition"
                                            disabled={true}
                                        />
                                    </ReferenceInput>
                                </Box>
                                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                                    <StatusSelectInput />
                                </Box>
                                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                                    <NumberInput
                                        source="sequence"
                                        isRequired
                                        fullWidth
                                    />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h4" gutterBottom mb={3}>
                                <Box fontWeight='fontWeightBold'>
                                    Easier
                                </Box>
                            </Typography>
                            <Box display={{ xs: 'block', sm: 'flex' }}>
                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                    <ReferenceInput
                                        isRequired
                                        fullWidth
                                        source="easier_exercise.exercise"
                                        reference="exerciselibrarys"
                                        filter={{ practice_id: { "in": ["EMBODI", localStorage.getItem('selectedPractice')] } }}
                                        sort={{ field: 'name', order: 'ASC' }}
                                    >
                                        <SelectInput optionValue="id" optionText="name" source="name" />
                                    </ReferenceInput>
                                </Box>
                                {/* <FormDataConsumer>
                                    {({ formData, ...rest }) => {
                                        formData.easier_exercise.exercise123 = "test123";
                                        formData.easier_exercise.exercise = "test1235";
                                        console.log(formData.easier_exercise);
                                        return (
                                            <TextInput
                                                source={formData.easier_exercise.exercise}
                                                {...rest}
                                            />
                                        )
                                    }}
                                </FormDataConsumer>
                                <DynamicInput /> */}
                                {/* <ZipLookUp /> */}
                            </Box>
                            <Box display={{ xs: 'block', sm: 'flex' }}>
                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                    <NumberInput
                                        source="easier_exercise.sets"
                                        isRequired
                                        fullWidth
                                    />
                                </Box>
                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                    <NumberInput
                                        source="easier_exercise.reps"
                                        isRequired
                                        fullWidth
                                    />
                                </Box>
                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                    <NumberInput
                                        source="easier_exercise.rest"
                                        isRequired
                                        fullWidth
                                    />
                                </Box>
                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                    <NumberInput
                                        source="easier_exercise.hold"
                                        isRequired
                                        fullWidth
                                    />
                                </Box>
                            </Box>
                            <Box display={{ xs: 'block', sm: 'flex' }}>
                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                    <TextInput
                                        source="easier_exercise.instructions"
                                        isRequired
                                        fullWidth
                                    />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h4" gutterBottom mb={3}>
                                <Box fontWeight='fontWeightBold'>
                                    Default
                                </Box>
                            </Typography>
                            <Box display={{ xs: 'block', sm: 'flex' }}>
                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                    <ReferenceInput
                                            isRequired
                                            fullWidth
                                            source="default_exercise.exercise"
                                            reference="exerciselibrarys"
                                            filter={{ practice_id: { "in": ["EMBODI", localStorage.getItem('selectedPractice')] } }}
                                            sort={{ field: 'name', order: 'ASC' }}
                                        >
                                            <SelectInput optionValue="id" optionText="name" source="name" />
                                        </ReferenceInput>
                                </Box>
                                {/* <Link to="/exerciselibrarys">
                                    <Button 
                                        label="+ SELECT FROM LIBRARY"
                                    ></Button>
                                </Link> */}
                            </Box>
                            <Box display={{ xs: 'block', sm: 'flex' }}>
                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                    <NumberInput
                                        source="default_exercise.sets"
                                        isRequired
                                        fullWidth
                                    />
                                </Box>
                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                    <NumberInput
                                        source="default_exercise.reps"
                                        isRequired
                                        fullWidth
                                    />
                                </Box>
                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                    <NumberInput
                                        source="default_exercise.rest"
                                        isRequired
                                        fullWidth
                                    />
                                </Box>
                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                    <NumberInput
                                        source="default_exercise.hold"
                                        isRequired
                                        fullWidth
                                    />
                                </Box>
                            </Box>
                            <Box display={{ xs: 'block', sm: 'flex' }}>
                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                    <TextInput
                                        source="default_exercise.instructions"
                                        isRequired
                                        fullWidth
                                    />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h4" gutterBottom mb={3}>
                                <Box fontWeight='fontWeightBold'>
                                    Harder
                                </Box>
                            </Typography>
                            <Box display={{ xs: 'block', sm: 'flex' }}>
                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                    <ReferenceInput
                                        isRequired
                                        fullWidth
                                        source="harder_exercise.exercise"
                                        reference="exerciselibrarys"
                                        filter={{ practice_id: { "in": ["EMBODI", localStorage.getItem('selectedPractice')] } }}
                                        sort={{ field: 'name', order: 'ASC' }}
                                    >
                                        <SelectInput optionValue="id" optionText="name" source="name" />
                                    </ReferenceInput>
                                </Box>
                                {/* <Link to="/exerciselibrarys">
                                    <Button 
                                        label="+ SELECT FROM LIBRARY"
                                    ></Button>
                                </Link> */}
                            </Box>
                            <Box display={{ xs: 'block', sm: 'flex' }}>
                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                    <NumberInput
                                        source="harder_exercise.sets"
                                        isRequired
                                        fullWidth
                                    />
                                </Box>
                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                    <NumberInput
                                        source="harder_exercise.reps"
                                        isRequired
                                        fullWidth
                                    />
                                </Box>
                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                    <NumberInput
                                        source="harder_exercise.rest"
                                        isRequired
                                        fullWidth
                                    />
                                </Box>
                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                    <NumberInput
                                        source="harder_exercise.hold"
                                        isRequired
                                        fullWidth
                                    />
                                </Box>
                            </Box>
                            <Box display={{ xs: 'block', sm: 'flex' }}>
                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                    <TextInput
                                        source="harder_exercise.instructions"
                                        isRequired
                                        fullWidth
                                    />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} className="hidden">
                            <TextInput
                                disabled={true}
                                source="patient_id"
                                label="Patient Id"
                            />
                        </Grid>
                    </Grid>
                </Box>
            </SimpleForm>
        </Edit>
    );
};

const StatusSelectInput = (props) => {
    const { record } = useEditContext();

    return (
        <SelectInput 
            isRequired
            fullWidth
            source="status"
            emptyText="Active" // Default values
            emptyValue='active' // Default values
            choices={[
                { id: 'active', name: 'Active' },
                { id: 'inactive', name: 'Inactive' },
            ]} 
            format={(value) => {
              if (['inProgress', 'incomplete', 'completed', 'active'].includes(value)) {
                return 'active';
              }
              return 'inactive';
            }}
            parse={(value) => {
              if (value === 'active') {
                  if (record?.status === 'inactive') {
                      return 'active';
                  }
                  return record?.status;
              }
              return 'inactive'
            }}
        />
    )
}

export default ExerciseEdit;
