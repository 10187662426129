import { Modal, Box, Typography } from '@mui/material';
import { Button, fetchUtils, ReferenceInput, required, SaveButton, SelectInput, SimpleForm, TextInput, Toolbar, useNotify, useRecordContext } from 'react-admin';
import tokenManager from '../../../tokenManager';
import { useState } from 'react';

const CreateCustomHomeExercisePlanModal = ({ setKey, open, onClose }) => {

    const record = useRecordContext();
    const notify = useNotify()
    const [loading, setLoading] = useState(false)

    const onSubmit = async (data) => {
        setLoading(true)
        const newData = {...data}
        newData.user_id = record.id
        newData.practice_id = localStorage.getItem('selectedPractice')
        if (!newData.approval_status) {
            newData.approval_status = 'pending'
        }
      
        const apiUrl = (window.location.hostname === "localhost") ? 'http://localhost:1337' : '';
        const httpClient = fetchUtils.fetchJson;
        const accessToken = tokenManager.getToken();

        httpClient(`${apiUrl}/kaizenovate/provider/1.0.0/custom-hep/create`, {
            method: 'POST',
            credentials: 'include',
            user: {
                authenticated: true,
                token: `Bearer ${accessToken}`,
            },
            body: JSON.stringify(newData),
        }).catch((e)=> {
            console.log(`Error in create custom HEP`)
            console.error(e)
            notify('Error: Could not create custom HEP', {type: 'error'})
            setLoading(false)
            throw e
        }).then((response) => {
            if (response.json.error) {
                console.error(response.json.error)
                notify('Error: Could not create custom HEP', {type: 'error'})
                setLoading(false)
                throw new Error(response.json.error)
            } else {
                notify('Custom HEP created')
                setLoading(false)
                setKey(Date.now())
                onClose()
            }
        })
    }

    const CustomToolbar = ( onCancel ) => {
      return <Toolbar style={{display: "flex", justifyContent:"space-between", alignItems: "center"}}>
          <Button
              label='Cancel'
              size='medium'
              variant='contained'
              onClick={onCancel.onCancel}
          />
          <SaveButton disabled={loading} />
      </Toolbar>
    }

    return (
        <Modal open={open} onClose={onClose}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                }}
            >
                <SimpleForm onSubmit={onSubmit} toolbar={<CustomToolbar onCancel={onClose}/>} record={{}}>
                    <Typography variant="h5" gutterBottom mb={4}>
                        Add Custom Home Exercise Plan
                    </Typography>

                    <Typography variant="body2" gutterBottom mb={4}>
                        {"You are able to assign any protocol to this patient, but the protocols must be defined under Home Exercises > Protocols."}
                    </Typography>

                    <Typography variant="body2" gutterBottom sx={{ fontWeight: 'bold' }}>
                        {"1. Name this condition"}
                    </Typography>
                    <TextInput source="name" label="Condition Name" fullWidth validate={required()} isRequired/>

                    <Typography variant="body2" gutterBottom sx={{ fontWeight: 'bold' }}>
                        {"2. Select a protocol"}
                    </Typography>
                    <ReferenceInput
                        source="masterprogram_id"
                        reference="masterprograms"
                        label="Protocol"
                        filter={{ practice_id: { "in": ["EMBODI", localStorage.getItem('selectedPractice')] } }}
                        sort={{ field: 'name', order: 'ASC' }}
                        fullWidth
                    >
                        <SelectInput
                            optionText={(record) => {
                                let result = record.name
                                if (record.created_by === "EMBODI") {
                                    result += " (EMBODI)"
                                } else if (record.created_by === localStorage.getItem('selectedPractice')) {
                                    result += ` (${localStorage.getItem('selectedPracticeName')})`
                                } else {
                                    console.error(`Unknown created_by value: ${record.created_by}`)
                                }

                                return result
                            }}
                            optionValue="id"
                            label="Protocol"
                            fullWidth
                            validate={required()}
                            isRequired
                        />
                    </ReferenceInput>

                    <Typography variant="body2" gutterBottom sx={{ fontWeight: 'bold' }}>
                        {"3. Provide approval (optional)"}
                    </Typography>
                    <SelectInput
                        source="approval_status"
                        label="Status"
                        choices={[
                            { id: 'approved', name: 'HEP Approved' },
                            { id: 'pending', name: 'HEP Pending' },
                        ]}
                        fullWidth
                    />
                </SimpleForm>
            </Box>
        </Modal>
    )
}

export default CreateCustomHomeExercisePlanModal;
