import * as React from "react";
import { Route } from 'react-router-dom';
import { Admin, CustomRoutes, Notification, Resource } from 'react-admin';
import Dashboard from './Dashboard';
import authProvider from './authProvider';

import Layout from './layout/Layout';

import dataProvider from './dataProvider';

import patients from './pages/patients';
import homeexercises from './pages/homeexercises';
import exercises from './pages/exercises';
import exerciselibrarys from './pages/exerciselibrarys';
import conditions from './pages/conditions';
import musclegroups from './pages/musclegroups';
import masterprograms from './pages/masterprograms';
import messages from './pages/messages'
import billings from './pages/billings'
import logs from './pages/logs'
import messagetemplates from './pages/messagetemplates'
import CreateMessageBroadcast from './pages/messagebroadcasts/Create'
import EditMessageBroadcast from './pages/messagebroadcasts/Edit'
import messageautomations from './pages/messageautomations'
import messagelogs from './pages/messagelogs'
import providernotifications from './pages/providernotifications'
import savedchatmessages from './pages/savedchatmessages'

import { Login } from './pages/login/Login';

// import { Card, Tab, Tabs } from "@blueprintjs/core"
import { useCallback, useEffect } from "react"
import RecoverPassword from "./pages/RecoverPassword";
import ResetPassword from "./pages/ResetPassword";
import RedirectPassword from "./pages/RedirectPassword";
import AcceptTerms from "./pages/AcceptTerms";
import Sent from "./pages/Sent";
import Success from "./pages/Success";
import { MessageLogShow } from "./pages/messagelogs/MessageLogShow";
import HomeExercises from "./pages/homeexercises/HomeExercisesList";


const App = () => {

    /**
     * Sync logout across tabs
     */
    const syncLogout = useCallback(event => {
        if (event.key === "logout") {
        // If using react-router-dom, you may call history.push("/")
        window.location.reload()
        }
    }, [])

    useEffect(() => {
        window.addEventListener("storage", syncLogout)
        return () => {
        window.removeEventListener("storage", syncLogout)
        }
    }, [syncLogout])

    // Handle redirects from www.kaizenovate.net to portal.embodihealth.com
    const oldURL = "www.kaizenovate.net"
    const newURL = "portal.embodihealth.com"

    useEffect(() => {
      const currentURL = window.location.href

      if (currentURL.includes(oldURL)) {
        const redirectURL = currentURL.replace(oldURL, newURL)
        window.location.replace(redirectURL)
      }
    }, [])

    return (<Admin
                dashboard={Dashboard}
                dataProvider={dataProvider}
                authProvider={authProvider}
                layout={Layout}
                loginPage={Login}
                requireAuth
                notification={() => <Notification autoHideDuration={10000}/>}
            >
                <Resource name="users" {...patients} recordRepresentation={(record) => `${record.first} ${record.last}`}/>
                <Resource name="messages" {...messages} />
                {/* <Resource name="articles" list={PostList} edit={PostEdit} create={PostCreate} icon={PostIcon} /> */}
                <Resource name="homeexercises" {...homeexercises} />
                <Resource name="exercises" {...exercises} />
                <Resource name="exerciselibrarys" {...exerciselibrarys} />
                <Resource name="conditions" {...conditions} />
                <Resource name="musclegroups" {...musclegroups} />
                <Resource name="masterprograms" {...masterprograms} />
                <Resource name="billings" {...billings} />
                <Resource name="patientBillings" />
                <Resource name="logentries" />
                <Resource name="providers" />
                <Resource name="savedchatmessage" {...savedchatmessages} />
                <Resource name="logs" {...logs}/>
                <Resource name="messagetemplates" {...messagetemplates} recordRepresentation={(record) => record.name}/>
                <Resource name="messagebroadcasts" create={<CreateMessageBroadcast />} edit={<EditMessageBroadcast/>} />
                <Resource name="messageautomations" {...messageautomations} recordRepresentation={(record) => record.name}/>
                <Resource name="messagelogs" {...messagelogs}  show={MessageLogShow} />
                <Resource name="providernotifications" {...providernotifications} />

                <CustomRoutes noLayout>
                    <Route path="/RecoverPassword" element={<RecoverPassword />} />
                    <Route path="/ResetPassword" element={<ResetPassword />} />
                    <Route path="/RedirectPassword" element={<RedirectPassword />} />
                    <Route path="/AcceptTerms" element={<AcceptTerms />} />
                    <Route path="/Sent" element={<Sent />} />
                    <Route path="/Success" element={<Success />} />
                </CustomRoutes>
            </Admin> )
};

export default App;