import { Box, Modal, Typography } from "@mui/material"
import ExerciseCreate from "../../../pages/exercises/ExerciseCreate";

const CreateProgressiveExerciseModal = ({ open, onClose, setKey, condition_id, user_id, addAfterExercise }) => {
    return (
        <Modal
            open={open}
            onClose={onClose}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: { xs: '100%', sm: '80%', md: '60%', lg: '50%' },
                    maxHeight: '90vh',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    overflowY: 'auto',
                }}
            >
                <Typography variant="h5" gutterBottom>
                    {`Create Exercise`}
                </Typography>
                <ExerciseCreate
                    onCancel={onClose}
                    onSuccess={() => {onClose(); setKey(Date.now());}}
                    condition_id={condition_id}
                    user_id={user_id}
                    addAfterExercise={addAfterExercise}
                />
            </Box>
        </Modal>
    )
}

export default CreateProgressiveExerciseModal;
