import { useCallback, useEffect, useState } from 'react';
import { StreamChat, logChatPromiseExecution, Channel as StreamChannel, ChannelFilters, DefaultGenerics} from 'stream-chat';
import {
  Chat,
  Channel,
  LoadingIndicator,
  MessageInput,
  MessageList,
  Thread,
  Window,
  useChannelStateContext,
  useChannelActionContext,
} from 'stream-chat-react';
import { Button, Grid } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { grey } from '@mui/material/colors';
import Row from 'react-bootstrap/Row';
import 'stream-chat-react/dist/css/index.css';
import '../../../scss/pages/EnhancedMessaging.scss';
// import { CustomPreview } from './CustomPreview';
import './CustomPreview.scss';
import { CustomMessage } from './CustomMessage';
import { CustomMessageInput } from './CustomMessageInput';
import { format, intervalToDuration, formatDuration } from 'date-fns';
import {
  FieldProps,
  useGetOne,
  useRecordContext,
  useUpdate,
  useGetIdentity,
  useRedirect,
  AutocompleteInputProps,
  ReferenceInput,
  AutocompleteInput,
  SimpleForm,
  fetchUtils,
  useNotify,
} from 'react-admin';
import Modal from '@mui/material/Modal';
import { useFormContext } from 'react-hook-form';
import { Patient } from '../../../types';
import { useResolveAction } from '../../../resolveAction.js';
import { logActivity } from '../../../logActivity.js'
import SessionTimeout from '../../../SessionTimeout';
import './Messages.scss';
import { SecureMessageToggle } from '../../../components/messages/SecureMessageToggle';
import ScheduleMessage from '../../../components/messages/ScheduleMessage';
import tokenManager from '../../../tokenManager';
import moment from 'moment-timezone';
import { SavedChatMessageCreateModal } from '../../savedchatmessages/Create';
import { SavedChatMessageEditModal } from '../../savedchatmessages/Edit';

const Messages = () => {
  let selectdPractice = localStorage.getItem('selectedPractice') || ''
  const [chatClient, setChatClient] = useState<StreamChat | null>(null);
  const [customType, setCustomType] = useState("secure");
  const [channel, setChannel] = useState<StreamChannel | null>(null);
  const [noChannels, setNoChannels] = useState(false);
  const [createdChannel, setCreatedChannel] = useState(false);
  const [createChannelSubmitting, setCreateChannelSubmitting] = useState(false);
  const redirect = useRedirect()
  const notify = useNotify();

  const toggleCustomTypeSchedule = (props: FieldProps<Patient>) => {
    setCustomType(customType === "schedule" ? "secure" : "schedule");
  };

  const record = useRecordContext<Patient>();

  useEffect(() => {
    const client = StreamChat.getInstance(process.env.REACT_APP_STREAM_API_KEY!, { timeout: 30000 });
    try {
      const initChat = async () => {
        setChatClient(client);
        
        let selectedChannel;
        const fetchSize = 2;
        // let lastFetch = fetchSize;

        const filter: ChannelFilters<DefaultGenerics> = record.chat_channel_id ? { id: record.chat_channel_id } : {
          member_count: 2,
          type: 'messaging',
          $and: [
            { members: { $in: [selectdPractice.toString()] } },
            { members: { $in: [record.id.toString()] } },
          ],
        };
        // const options = {watch: true, state: false, limit: fetchSize, offset: 0};
        await client.queryChannels(filter).then(r => {
          if (r.length === 1) {
            selectedChannel = r[0];
            setNoChannels(false)
          } else {
            setNoChannels(true)
            console.log("No chat channels found for this user")
          }
        })

        setChannel(selectedChannel);
      };

      initChat();
    } catch (e) {
      console.log(e);
    }
  }, [record.id, selectdPractice, createdChannel]);

  const createChatChannel = () => {
    if (localStorage.getItem('enhancedMessagingEnabled') !== 'true') {
      console.error("Enhanced Messaging is not enabled - cannot create chat channel")
      return;
    }

    setCreateChannelSubmitting(true)

    const apiUrl = (window.location.hostname === "localhost") ? 'http://localhost:1337' : '';
    const accessToken = tokenManager.getToken();

    const options = {}
    options['credentials'] = 'include'
    options['user'] = {
      authenticated: true,
      token: `Bearer ${accessToken}`,
    }

    const httpClient = fetchUtils.fetchJson;

    httpClient(`${apiUrl}/kaizenovate/provider/1.0.0/create-chat-channel`, {
      method: 'POST',
      credentials: 'include',
      user: {
        authenticated: true,
        token: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        user_id: record.id.toString(),
        practice_id: localStorage.getItem('selectedPractice')
      }),
    }).then((result) => {
      if (result.json.message) {
        notify(result.json.message, {type: 'error'})
        setCreateChannelSubmitting(false)
      } else if (result.json.channel_id) {
        setCreateChannelSubmitting(false)
        setCreatedChannel(true);
      } else {
        notify("An error occurred. Please try again later.", {type: 'error'})
        setCreateChannelSubmitting(false)
      }
    }).catch((e)=> {
      notify("An error occurred. Please try again later.", {type: 'error'})
      setCreateChannelSubmitting(false)
      console.log(`Error in PatientMessages:createChatChannel()`)
      console.error(e)
      throw e
    })
  }

  if (noChannels) {
    return (
      <div>
        <div>
          No messages available for this patient. Messages will be automatically enabled once the patient has set up their account.
        </div>
        {/* Don't show this button if enhancedMessaging is disabled - if user has downloaded the app they will have a channel. */}
        {/* Without enhanced messaging we cannot send any texts - so this channel will be useless if the user does not have the app */}
        {localStorage.getItem('enhancedMessagingEnabled') === 'true' && <div style={{ paddingTop: '10px' }}>
          <Button
            startIcon={<AddIcon />}
            sx={{ backgroundColor: 'blue' }}
            variant="contained"
            onClick={createChatChannel}
            disabled={createChannelSubmitting}
          >
            Create Messaging Channel
          </Button>
        </div>}
      </div>
    )
  }

  if (!chatClient || !channel) {
    return <LoadingIndicator />;
  }

  return (
    <div className={`chat-container${localStorage.getItem('enhancedMessagingEnabled') === 'true' ? '-' + customType : ''}`}>
      <SessionTimeout isAuthenticated={true} logOut={() => {redirect('/users')}}/>
      <Chat client={chatClient} theme='messaging light'>
        <Row>
          {/* <ChannelList 
          filters={filters}  
          showChannelSearch 
          Preview={CustomPreview}
          // Preview={(client) => <CustomPreview client={client} />}
          // List={CustomListContainer}
          /> */}
          <Channel Message={CustomMessage} Input={CustomMessageInput} channel={channel} >
            <Window>
              {/* <ChannelHeader /> */}
              <CustomChannelHeader customType={customType} toggleScheduleOnClick={toggleCustomTypeSchedule} />
              <MessageList />
              <CustomMessageBoxInput customType={customType} setCustomType={setCustomType} toggleScheduleOnSubmit={toggleCustomTypeSchedule} record={record} />
            </Window>
            <Thread />
          </Channel>
        </Row>

      </Chat>
    </div>
  );
};

const CustomChannelHeader = ( 
  { customType, toggleScheduleOnClick }
  // , props: ChannelHeaderProps
  ) => {

  //date for automated testing, 0 if not testing
  var date = Number(localStorage.getItem('testDate'))
  if (!date) date = 0;

  const { channel } = useChannelStateContext();
  var currentCustomType = customType;
  const { identity, isLoading: identityLoading } = useGetIdentity();

  const currentChannelPatient = Object.values(channel.state.members).find((user) => (
    user.user!.id !== localStorage.getItem('selectedPractice')
  ));

  const [seconds, setSeconds] = useState(0);
  const [formattedSeconds, setFormattedSeconds] = useState("00:00");
  // const record = useRecordContext<Patient>();
  const [update] = useUpdate();

  var record = useGetOne('users', { id: currentChannelPatient?.user_id }).data; 
  // console.log(record);

  // add new activity log
  const updateWithNewRecord = useCallback((newActivity:any) => {

    if (newActivity.duration <= 1) return

      var currDate = record.start_billing_date;
      var currTime = record.start_billing_time;
      var currBillingDuration = record.current_billing_duration;

      if( currDate == null || 
          currDate === "" ||
          currTime == null || 
          currTime === "" ) {
          currDate = format(new Date(), 'yyyy-MM-dd');
          currTime = format(new Date(), 'HH:mm');
      }
      
      if( currBillingDuration == null || 
          currBillingDuration === 0) {
          currBillingDuration = newActivity.duration
      } else {
          currBillingDuration = record.current_billing_duration + newActivity.duration
      }

      // add to the beginning of array - the order of these activities are in the same order as displayed on activity log table ... bottom to top
      const diff = { 
          current_billing_duration: currBillingDuration,
          start_billing_date: currDate,
          start_billing_time: currTime,
      };

      update(
          'users',
          { id: record.id, data: diff, previousData: record }
      )

      logActivity('PROVIDER.View.Messages', newActivity.activity, newActivity.comment, record.id, newActivity.duration, date)
  }, [record, date, update])

  // Add activity log when moving away from Patient Message
  useEffect(
    () => {
      return () => {
        const startTime = new Date();
        const duration = Math.round((new Date().getTime() - startTime.getTime()) / 1000);
        const newActivity = {
          "date": Math.round(startTime.getTime() / 1000),
          "duration": duration,
          "activity": "Viewed Patient Message",
          "first": identity?.name ?? "",
          "last": "", // TODO get lastname
          "email": identity?.email ?? "",
          "picture": identity?.picture ?? "",
          "comment": "None",
          "billable": false // TODO check to see if the current viewing period is billable
        };
        updateWithNewRecord(newActivity);
      }
    },
    [!identityLoading, identity?.email, identity?.name, identity?.picture, updateWithNewRecord]
  );

  useEffect(() => {
    setTimeout(() => setSeconds(seconds + 1), 1000);
    const duration = intervalToDuration({ start: 0, end: seconds * 1000 });
    const zeroPad = (num) => String(num).padStart(2, "0");
    const fSeconds = formatDuration(duration, {
      format: seconds >= 3600 ? ["hours", "minutes", "seconds"] : ["minutes", "seconds"],
      zero: true,
      delimiter: ":",
      locale: {
        formatDistance: (_token, count) => zeroPad(count)
      }
    });
    setFormattedSeconds( fSeconds );
  }, [seconds]);

  return (
    <div className='str-chat__header custom-header'>
      <div className='top-info-container'>
        <div><span className='t-grey'>To:  </span>{currentChannelPatient?.user?.name}</div>
        <div><span className='t-grey'>Timer:  </span>{formattedSeconds}</div>
        <div className='schedule-appointment' >
          <div className='schedule-appointment'><Button onClick={() => channel.markRead()}>Mark as Read</Button></div>
          <div className='schedule-appointment'><Button onClick={() => toggleScheduleOnClick()}><div className={ currentCustomType === "schedule" ? 'minus' : 'plus' }></div>Schedule Appointment</Button></div>
        </div>
      </div>
      {record?.patient_actions.length > 0 ? 
        <div className="checkboxes-container">
          <span className='t-grey'>Click to Resolve Patient Actions: </span> <ResolveActionCheckboxes record={record}/>
        </div> : <div></div>
      } 
    </div>
  );
};

const getSavedMessage = async ({savedChatMessageRecord, userRecord, setSavedChatMessage, setMessageInputKey, setSavedChatMessageRecord, messageInputKey, notify}) => {

    let apiUrl = (window.location.hostname === "localhost") ? 'http://localhost:1337' : '';
    apiUrl += '/kaizenovate/provider/1.0.0/use-saved-message';
    const httpClient = fetchUtils.fetchJson;
    const accessToken = tokenManager.getToken();

    apiUrl += `?practice_id=${localStorage.getItem('selectedPractice') || ''}&saved_message_id=${savedChatMessageRecord.id}&user_id=${userRecord.id}`

    const response = await httpClient(`${apiUrl}`, {
        method: 'GET',
        credentials: 'include',
        user: {
            authenticated: true,
            token: `Bearer ${accessToken}`,
        }
    }).catch((e)=> {
        console.log(`Error in SavedChatMessageDropdown.handleChange()`)
        console.error(e)
        notify('Unable to replace dynamic text', {type: 'error'})
        setSavedChatMessage(savedChatMessageRecord.message)
        setMessageInputKey(messageInputKey + 1)
        return
    })

    if (response && response.body && JSON.parse(response.body).message) {
        //console.log("response", response)
        // notify('Message schedueled', {type: 'info'})
        setSavedChatMessage(JSON.parse(response.body).message)
        setSavedChatMessageRecord(savedChatMessageRecord)
        setMessageInputKey(messageInputKey + 1)
    } else {
        notify('Unable to replace dynamic text', {type: 'error'})
        setSavedChatMessage(savedChatMessageRecord.message)
        setMessageInputKey(messageInputKey + 1)
    }
}

const SavedChatMessageDropDown = ({ savedChatMessage, setSavedChatMessage, setSavedChatMessageRecord, setResetSavedChatMessage, userRecord, setMessageInputKey, messageInputKey }) => {
  const notify = useNotify()
  const handleChange: AutocompleteInputProps['onChange'] = async (
    value,
    savedChatMessageRecord
  ) => {
    if (savedChatMessageRecord && savedChatMessageRecord.message) {
      if (savedChatMessageRecord.message !== savedChatMessage) {
        getSavedMessage({savedChatMessageRecord, userRecord, setSavedChatMessage, setMessageInputKey, setSavedChatMessageRecord, messageInputKey, notify})
      }
    } else {
      // console.log("no record selected")
      setResetSavedChatMessage(true)
    }
  };
  return <ReferenceInput
    source='savedchatmessage_id'
    reference='savedchatmessages'
    filter={{ practice_id: localStorage.getItem('selectedPractice') || "noSelectedPractice" }}
    sort={{ field: "name", order: "ASC" }}
  >
    <AutocompleteInput
      sx={{ "& .MuiInputBase-root": { height: "38px" } }} 
      size='small'
      className='drop-down'
      optionText='name'
      label='Select a message...'
      filterToQuery={(value) => ({ name: value })}
      onChange={handleChange}
      helperText={false}
    />
  </ReferenceInput>
}

const CustomMessageBoxInput = ( { customType, toggleScheduleOnSubmit, setCustomType, record } ) => {
  const { sendMessage } = useChannelActionContext();
  const { channel } = useChannelStateContext();
  // putting the sms_message text into state
  const [savedChatMessage, setSavedChatMessage] = useState("")
  const [savedChatMessageRecord, setSavedChatMessageRecord] = useState(null);
  const [scheduleDate, setScheduleDate] = useState("");
  const [scheduleTime, setScheduleTime] = useState("");
  const [isSchedule, setIsSchedule] = useState(false);
  const [scheduleModalOpen, setScheduleModalOpen] = useState(false)
  const [scheduleModalError, setScheduleModalError] = useState(false)
  const [resetSavedChatMessage, setResetSavedChatMessage] = useState(false);
  const [messageInputKey, setMessageInputKey] = useState(0)
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const notify = useNotify();

  useEffect(() => {
    if (localStorage.getItem('enhancedMessagingEnabled') !== 'true') {
      setCustomType("secure")
    } else if ((channel && channel.data && channel.data.unknown_user)) {
      setCustomType("non-secure")
    } else if (localStorage.getItem('isAdmin') === 'true' || localStorage.getItem('isBillable') === 'true') {
      setCustomType("secure")
    } else {
      setCustomType("non-secure")
    }
  }, [channel, setCustomType])

  useEffect(() => {
    if (isSchedule && scheduleDate && scheduleTime) {
      const scheduleBtn = document.querySelector('.str-chat__send-button') as HTMLElement
      scheduleBtn.click()
    }
  }, [isSchedule, scheduleDate, scheduleTime]);

  var currentCustomType = customType;

  const overrideSubmitHandler = (message) =>  {
    channel.markRead()

    let customMessageData = { ...message, customType: currentCustomType };
    if(currentCustomType === "schedule") toggleScheduleOnSubmit();

    if (isSchedule) {
      if (scheduleDate && scheduleTime) {
        const practiceTZ = localStorage.getItem('selectedPracticeTZ') || 'America/New_York'
        const dateTime = `${scheduleDate} ${scheduleTime}:00`
        customMessageData.schedule_date_time = moment.tz(dateTime, practiceTZ).unix()
        scheduleMessage(customMessageData)
      }
      setIsSchedule(false)
    } else {
      const sendMessagePromise = sendMessage(message, customMessageData);
      logChatPromiseExecution(sendMessagePromise, 'send message');
      setResetSavedChatMessage(true)
      setMessageInputKey(messageInputKey + 1)
    }
  };

  const ResetSavedChatMessage = () => { // workaround for conditionally triggering useFormContext
    setSavedChatMessage("")
    setSavedChatMessageRecord(null)
    useFormContext().setValue('savedchatmessage_id', "")
    setResetSavedChatMessage(false)
    setMessageInputKey(messageInputKey + 1)
    return null
  }

  const scheduleMessage = async (message: any) => {
    let apiUrl = (window.location.hostname === "localhost") ? 'http://localhost:1337' : '';
    apiUrl += '/kaizenovate/provider/1.0.0/schedule-chat-message';
    const httpClient = fetchUtils.fetchJson;
    const accessToken = tokenManager.getToken();

    message.practice_id = localStorage.getItem('selectedPractice') || '';
    message.channel_id = channel.id;

    const textInput = document.querySelector('.str-chat__textarea') as HTMLElement
    const preSendText = textInput!.querySelector('textarea')!.value

    const response = await httpClient(`${apiUrl}`, {
      method: 'POST',
      credentials: 'include',
      user: {
          authenticated: true,
          token: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
          ...message,
      }),
    }).catch((e)=> {
        console.log(`Error in submitTestMessage()`)
        console.error(e)
        // notify('Unable to schedule message', {type: 'error'})
        return
    })

    if (response && response.status === 200) {
      console.log("response", response)
      setScheduleModalError(false)
      setScheduleModalOpen(false)
      setIsSchedule(false)
      setResetSavedChatMessage(true)
      // notify('Message schedueled', {type: 'info'})
    } else {
      setScheduleModalError(true)
      setIsSchedule(false)
      setSavedChatMessage(preSendText)
    }
    setMessageInputKey(messageInputKey + 1)
  }

  const CreateSavedMessageModal = () => {
    
      const customOnCancelCallback = () => {
        setCreateModalOpen(false)
      }

      const customOnSuccessCallback = () => {
        notify('Saved Message created successfully');
        setCreateModalOpen(false)
      }

      return (
          <Modal
              open={createModalOpen}
              onClose={customOnCancelCallback}
              className='messages-saved-chat-message-modal'
          >
              <div>
                  <SavedChatMessageCreateModal onCancel={customOnCancelCallback} onSuccess={customOnSuccessCallback}/>
              </div>
          </Modal>
      );
  };

  const EditSavedMessageModal = ({messageRecord}) => {

      const customOnCancelEdit = () => {
          setEditModalOpen(false)
      }

      const customOnSuccess = () => {
          notify('Saved Message updated successfully');
          getSavedMessage({savedChatMessageRecord: messageRecord, userRecord: record, setSavedChatMessage, setMessageInputKey, setSavedChatMessageRecord, messageInputKey, notify})
          setEditModalOpen(false)
      }

      return (
          <Modal
              open={editModalOpen}
              onClose={customOnCancelEdit}
              className='messages-saved-chat-message-modal'
          >
              <div>
                  <SavedChatMessageEditModal record={messageRecord} onCancel={customOnCancelEdit} onSuccess={customOnSuccess}/>
              </div>
          </Modal>
      )
  }

  return (
    <div className='custom-message-input-container'>
      {currentCustomType === "schedule" ? (
        <div className="schedule-appointment-tag">Schedule an Appointment</div>
      ) : ""}
      <Grid container p={0} columnSpacing={1} alignItems="center">
        <Grid item xs={12} justifyContent="flex-end">
          <SimpleForm className="form-m-p" toolbar={false} sx={{ paddingRight: '85px' }}>
            <Grid container alignItems="center" gap="10px">
              <Grid item maxWidth="500px" width="400px">
                <SavedChatMessageDropDown
                  savedChatMessage={savedChatMessage}
                  setSavedChatMessage={setSavedChatMessage}
                  setSavedChatMessageRecord={setSavedChatMessageRecord}
                  setResetSavedChatMessage={setResetSavedChatMessage}
                  setMessageInputKey={setMessageInputKey}
                  messageInputKey={messageInputKey}
                  userRecord={record}
                />
                {resetSavedChatMessage && <ResetSavedChatMessage />}
              </Grid>
              <CreateSavedMessageModal />
              <EditSavedMessageModal messageRecord={savedChatMessageRecord} />
              <Grid item>
                <Button
                  startIcon={<AddIcon />}
                  sx={{ backgroundColor: grey[600] }}
                  variant="contained"
                  onClick={() => setCreateModalOpen(true)}
                >
                  Create
                </Button>
              </Grid>
              <Grid item>
                <Button
                  startIcon={<EditIcon />}
                  sx={{ backgroundColor: grey[600] }}
                  variant="contained"
                  onClick={() => {
                    if (savedChatMessageRecord != null) {
                      setEditModalOpen(true)
                    } else {
                      notify('Please select a message to edit', {type: 'info'})
                    }
                  }}
                >
                  Edit
                </Button>
              </Grid>
            </Grid>
            <Grid item>
              {(localStorage.getItem('embodiConnectEnabled') === 'true' && localStorage.getItem('isConnectUser') === 'true') ? 
              <ScheduleMessage
                setScheduleDate={setScheduleDate}
                setScheduleTime={setScheduleTime}
                setIsSchedule={setIsSchedule}
                scheduleModalOpen={scheduleModalOpen}
                setScheduleModalOpen={setScheduleModalOpen}
                scheduleModalError={scheduleModalError}
                setScheduleModalError={setScheduleModalError}
              /> : null}
            </Grid>
          </SimpleForm>
        </Grid>
      </Grid >

      <MessageInput
        overrideSubmitHandler={overrideSubmitHandler}
        grow={true}
        getDefaultValue={() => { return savedChatMessage || "" }}
        key={messageInputKey}
      />

      {localStorage.getItem('enhancedMessagingEnabled') === 'true' ?
      <Grid item xs={5}>
          <SecureMessageToggle 
            customType={customType} 
            setCustomType={setCustomType} 
            channel={channel} 
            record={record} 
          />
        </Grid> : null}
    </div >
  )
}

const ResolveActionCheckboxes = ( record ) => {
  return useResolveAction(record.record)
}

export default Messages;