import { Modal, Box, Typography } from '@mui/material';
import { Button, fetchUtils, required, SaveButton, SimpleForm, TextInput, Toolbar, useNotify } from 'react-admin';
import tokenManager from '../../../tokenManager';
import { useState } from 'react';

const EditCustomHomeExercisePlanModal = ({ record, open, onClose, setKey }) => {
    const notify = useNotify()
    const [loading, setLoading] = useState(false)

    const onSubmit = async (data) => {
        setLoading(true)
      
        const apiUrl = (window.location.hostname === "localhost") ? 'http://localhost:1337' : '';
        const httpClient = fetchUtils.fetchJson;
        const accessToken = tokenManager.getToken();

        httpClient(`${apiUrl}/kaizenovate/provider/1.0.0/custom-hep/edit`, {
            method: 'POST',
            credentials: 'include',
            user: {
                authenticated: true,
                token: `Bearer ${accessToken}`,
            },
            body: JSON.stringify(data),
        }).catch((e)=> {
            console.log(`Error in edit custom HEP`)
            console.error(e)
            notify('Error: Could not edit custom HEP', {type: 'error'})
            setLoading(false)
            throw e
        }).then((response) => {
            if (response.json.error) {
                console.error(response.json.error)
                notify('Error: Could not edit custom HEP', {type: 'error'})
                setLoading(false)
                throw new Error(response.json.error)
            } else {
                notify('Custom HEP updated')
                setLoading(false)
                setKey(Date.now())
                onClose()
            }
        })
    }

    const CustomToolbar = ( onCancel ) => {
      return <Toolbar style={{display: "flex", justifyContent:"space-between", alignItems: "center"}}>
          <Button
              label='Cancel'
              size='medium'
              variant='contained'
              onClick={onCancel.onCancel}
          />
          <SaveButton disabled={loading} />
      </Toolbar>
    }

    return (
        <Modal open={open} onClose={onClose}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                }}
            >
                <SimpleForm record={record} onSubmit={onSubmit} toolbar={<CustomToolbar onCancel={onClose}/>}>
                    <Typography variant="h5" gutterBottom mb={4}>
                        Edit Custom Home Exercise Plan
                    </Typography>
                    <TextInput source="name" label="Condition Name" fullWidth validate={required()} isRequired/>
                </SimpleForm>
            </Box>
        </Modal>
    )
}

export default EditCustomHomeExercisePlanModal;
