import { useRecordContext } from "react-admin"
import { Exerciselibrary } from "../../types"

const VideoField = ({ source }: {source: string }) => {
    const record = useRecordContext<Exerciselibrary>()
    const videoUrl = record?.[source]

    if (!videoUrl) {
        return <span>No video available</span>
    }

    return (
        <video width="100%" poster={record?.thumbnail} controls>
            <source src={videoUrl} type="video/mp4" />
            Your browser does not support the video tag.
        </video>
    )
}

export default VideoField
