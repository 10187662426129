import { Box, Grid, Typography } from '@mui/material';
import { 
    Create,
    SimpleFormIterator,
    SimpleForm,
    TextInput,
    ArrayInput,
    SelectInput,
    ReferenceInput,
    Toolbar,
    Button,
    SaveButton,
    RadioButtonGroupInput,
    BooleanInput,
    required,
 } from 'react-admin';

import { useWatch } from 'react-hook-form';

import '../../scss/pages/Layouts.scss';
import TagListField from '../../components/homeexercises/TagListField';

const CustomToolbar = ( onCancel ) => {
  return <Toolbar style={{display: "flex", justifyContent:"space-between", alignItems: "center"}}>
      <Button
          label='Cancel'
          size='medium'
          variant='contained'
          onClick={onCancel.onCancel}
      />
      <SaveButton />
  </Toolbar>
}

export const MasterprogramsCreateModal = ({ onCancel, onSuccess, record, adminMode, copyMode }) => {
    return <MasterprogramsCreateForm onCancel={onCancel} onSuccess={onSuccess} record={record} adminMode={adminMode} copyMode={copyMode} />;
}

const MasterprogramsCreateForm = ({ onCancel, onSuccess, record, adminMode, copyMode }) => {

    const modifiedRecord = {
        ...record,
        tags: [],
    }

    const transform = (data) => {
        let newData = {...data}
        delete newData.id
        newData.created_by = adminMode ? "EMBODI" : localStorage.getItem('selectedPractice')

        if (adminMode) {
            newData.override_default = false
        }
        return newData
    }

    const ConditionalReferenceInput = () => {
        const overrideDefault = useWatch({ name: 'override_default' })

        return (
            <div style={{ display: overrideDefault ? 'block' : 'none' }}>
                <ArrayInput source="override_condition_ids" label={"Add all conditions that should get this protocol to the list below."} defaultValue={['']}>
                    <SimpleFormIterator className='RaSimpleFormIterator-form-flex-row'>
                        <ReferenceInput
                            source=""
                            reference="conditions"
                            filter={{ custom_hep: false }}
                            sort={{ field: 'name', order: 'ASC' }}
                        >
                            <SelectInput optionValue="id" optionText="name" source="name" label="Condition" isRequired validate={overrideDefault ? required() : undefined} fullWidth/>
                        </ReferenceInput>
                    </SimpleFormIterator>
                </ArrayInput>
            </div>
        )
    }

    const ConditionalExerciseInput = () => {
        const hepType = useWatch({ name: 'hep_type' })

        const defaultExerciseRequired = (value) => {
            if (!value) {
                return 'Please select an exercise or remove this row'
            }
            return undefined
        }

        return (
            <>
                {hepType === 'static' && (
                    <Box display={{ xs: 'block', sm: 'flex' }} width='100%'>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }} width='100%'>
                            <Typography sx={{ fontWeight: 'bold' }}>
                                Daily Exercises
                            </Typography>
                            <ArrayInput source="static_exercises" label={false}>
                                <SimpleFormIterator className='RaSimpleFormIterator-form-flex-row' fullWidth>
                                    <ReferenceInput
                                        source="default_exercise_id"
                                        reference="exerciselibrarys"
                                        filter={{ practice_id: { "in": ["EMBODI", localStorage.getItem('selectedPractice')] } }}
                                        sort={{ field: 'name', order: 'ASC' }}
                                    >
                                        <SelectInput label="Exercise" optionValue="id" optionText="name" source="name" isRequired validate={defaultExerciseRequired} fullWidth/>
                                    </ReferenceInput>
                                </SimpleFormIterator>
                            </ArrayInput>
                        </Box>
                    </Box>
                )}

                {hepType === 'progressive' && (
                    <>
                        <Box display={{ xs: 'block', sm: 'flex' }} width='100%'>
                            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }} width='100%'>
                                <Typography sx={{ fontWeight: 'bold' }}>
                                    Level 1 - Relieve Exercises
                                </Typography>
                                <ArrayInput source="relieve_exercises" label={false}>
                                    <SimpleFormIterator className='RaSimpleFormIterator-form-flex-row' fullWidth>
                                        <ReferenceInput
                                            source="easy_exercise_id"
                                            reference="exerciselibrarys"
                                            filter={{ practice_id: { "in": ["EMBODI", localStorage.getItem('selectedPractice')] } }}
                                            sort={{ field: 'name', order: 'ASC' }}
                                        >
                                            <SelectInput optionValue="id" optionText="name" source="name" fullWidth/>
                                        </ReferenceInput>
                                        <ReferenceInput
                                            source="default_exercise_id"
                                            reference="exerciselibrarys"
                                            filter={{ practice_id: { "in": ["EMBODI", localStorage.getItem('selectedPractice')] } }}
                                            sort={{ field: 'name', order: 'ASC' }}
                                        >
                                            <SelectInput optionValue="id" optionText="name" source="name" isRequired validate={defaultExerciseRequired} fullWidth />
                                        </ReferenceInput>
                                        <ReferenceInput
                                            source="hard_exercise_id"
                                            reference="exerciselibrarys"
                                            filter={{ practice_id: { "in": ["EMBODI", localStorage.getItem('selectedPractice')] } }}
                                            sort={{ field: 'name', order: 'ASC' }}
                                        >
                                            <SelectInput optionValue="id" optionText="name" source="name" fullWidth />
                                        </ReferenceInput>
                                    </SimpleFormIterator>
                                </ArrayInput>
                            </Box>
                        </Box>

                        <Box display={{ xs: 'block', sm: 'flex' }} width='100%'>
                            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }} width='100%'>
                                <Typography sx={{ fontWeight: 'bold' }}>
                                    Level 2 - Restore Exercises
                                </Typography>
                                <ArrayInput source="restore_exercises" label={false}>
                                    <SimpleFormIterator className='RaSimpleFormIterator-form-flex-row' fullWidth>
                                        <ReferenceInput
                                            source="easy_exercise_id"
                                            reference="exerciselibrarys"
                                            filter={{ practice_id: { "in": ["EMBODI", localStorage.getItem('selectedPractice')] } }}
                                            sort={{ field: 'name', order: 'ASC' }}
                                        >
                                            <SelectInput optionValue="id" optionText="name" source="name" fullWidth/>
                                        </ReferenceInput>
                                        <ReferenceInput
                                            source="default_exercise_id"
                                            reference="exerciselibrarys"
                                            filter={{ practice_id: { "in": ["EMBODI", localStorage.getItem('selectedPractice')] } }}
                                            sort={{ field: 'name', order: 'ASC' }}
                                        >
                                            <SelectInput optionValue="id" optionText="name" source="name" isRequired validate={defaultExerciseRequired} fullWidth />
                                        </ReferenceInput>
                                        <ReferenceInput
                                            source="hard_exercise_id"
                                            reference="exerciselibrarys"
                                            filter={{ practice_id: { "in": ["EMBODI", localStorage.getItem('selectedPractice')] } }}
                                            sort={{ field: 'name', order: 'ASC' }}
                                        >
                                            <SelectInput optionValue="id" optionText="name" source="name" fullWidth />
                                        </ReferenceInput>
                                    </SimpleFormIterator>
                                </ArrayInput>
                            </Box>
                        </Box>

                        <Box display={{ xs: 'block', sm: 'flex' }} width='100%'>
                            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }} width='100%'>
                                <Typography sx={{ fontWeight: 'bold' }}>
                                    Level 3 - Reinforce Exercises
                                </Typography>
                                <ArrayInput source="reinforce_exercises" label={false}>
                                    <SimpleFormIterator className='RaSimpleFormIterator-form-flex-row' fullWidth>
                                        <ReferenceInput
                                            source="easy_exercise_id"
                                            reference="exerciselibrarys"
                                            filter={{ practice_id: { "in": ["EMBODI", localStorage.getItem('selectedPractice')] } }}
                                            sort={{ field: 'name', order: 'ASC' }}
                                        >
                                            <SelectInput optionValue="id" optionText="name" source="name" fullWidth/>
                                        </ReferenceInput>
                                        <ReferenceInput
                                            source="default_exercise_id"
                                            reference="exerciselibrarys"
                                            filter={{ practice_id: { "in": ["EMBODI", localStorage.getItem('selectedPractice')] } }}
                                            sort={{ field: 'name', order: 'ASC' }}
                                        >
                                            <SelectInput optionValue="id" optionText="name" source="name" isRequired validate={defaultExerciseRequired} fullWidth />
                                        </ReferenceInput>
                                        <ReferenceInput
                                            source="hard_exercise_id"
                                            reference="exerciselibrarys"
                                            filter={{ practice_id: { "in": ["EMBODI", localStorage.getItem('selectedPractice')] } }}
                                            sort={{ field: 'name', order: 'ASC' }}
                                        >
                                            <SelectInput optionValue="id" optionText="name" source="name" fullWidth />
                                        </ReferenceInput>
                                    </SimpleFormIterator>
                                </ArrayInput>
                            </Box>
                        </Box>
                    </>
                )}
            </>
        )
    }

    return (
        <Create 
            resource="masterprograms"
            mutationOptions={{ onSuccess: onSuccess }}
            transform={transform}
        >
            <Box pt={2} sx={{ width: '100%' }} mt={{ xs: 2, sm: 1 }}>
                <SimpleForm
                    sx={{ pt: 0, pb: 0 }}
                    record={modifiedRecord}
                    toolbar={<CustomToolbar onCancel={onCancel} />}
                    defaultValues={{
                        hep_type: 'progressive',
                    }}
                >
                    <Grid container rowSpacing={1} mb={1} columnSpacing={3}>
                        <Grid item xs={8}>
                            <Typography sx={{ fontWeight: 'bold' }}>
                                Details
                            </Typography>
                            <TextInput source="name" isRequired validate={required()} fullWidth />
                        </Grid>
                        <Grid item xs={4}>
                            <TagListField
                                source="tags"
                                sourceType="masterprograms"
                                label="Tags"
                                reference="tags"
                                adminMode={adminMode}
                            />
                        </Grid>
                    </Grid>

                    { adminMode ? (
                        <Grid container rowSpacing={1} mb={2} columnSpacing={1}>
                            <Grid item xs={12}>
                                <Typography sx={{ fontWeight: 'bold' }}>
                                    Condition
                                </Typography>
                                <ReferenceInput
                                    source="condition_id"
                                    reference="conditions"
                                    filter={{ custom_hep: false }}
                                    sort={{ field: 'name', order: 'ASC' }}
                                >
                                    <SelectInput optionValue="id" optionText="name" isRequired validate={required()} source="name" />
                                </ReferenceInput>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid container rowSpacing={1} mb={2} columnSpacing={1}>
                            <Grid item xs={12}>
                                <Typography sx={{ fontWeight: 'bold' }}>
                                    Condition Mapping
                                </Typography>
                                <BooleanInput
                                    source='override_default'
                                    label='Select a condition and map this protocol to future patients instead of the EMBODI protocol'
                                    helperText={false}
                                />
                                <ConditionalReferenceInput />
                            </Grid>
                        </Grid>
                    )}

                    <Grid container rowSpacing={1} mb={1} columnSpacing={1}>
                        <Grid item xs={12}>
                            <Typography sx={{ fontWeight: 'bold' }}>
                                Protocol Type
                            </Typography>
                            <RadioButtonGroupInput
                                source='hep_type'
                                label=''
                                row={false}
                                choices={[
                                    { id: 'static', name: 'Static - patient performs all exercises daily' },
                                    { id: 'progressive', name: 'Progression - patient performs 3 exercises daily per condition and progresses over time with increased difficulty' },
                                ]}
                                isRequired
                                validate={required()}
                            />
                        </Grid>
                    </Grid>
                    
                    <ConditionalExerciseInput />
                </SimpleForm>
            </Box>
        </Create>
    );
};
