import { Box } from '@mui/material';
import {
    List,
    Datagrid,
    TextField,
    TopToolbar,
    CreateButton,
    ImageField,
    ReferenceField,
} from 'react-admin';

const ConditionsList = () => {

    return (
        <Box>
            <List
                actions={<ListActions />}
                filter={{ custom_hep: false }}
            >
                <Datagrid
                    optimized
                    rowClick="edit"
                    bulkActionButtons={false}
                >
                    <TextField source="name" />
                    <ImageField source="image" 
                        sx={{ '& img': { maxHeight: 100 } }}
                    />
                    <ReferenceField
                        label="Protocol"
                        source="masterprogram_id"
                        reference="masterprograms"
                        link={false}
                    >
                        <TextField source="name" />
                    </ReferenceField>
                    <TextField source="diagnosis_codes" />
                </Datagrid>
            </List>
        </Box>
    );
};

const ListActions = () => (
    <TopToolbar>
        <CreateButton/>
    </TopToolbar>
);

export default ConditionsList;
