import * as React from 'react';
import { useTheme, useMediaQuery } from '@mui/material';
import { Box, ImageList, ImageListItem, ImageListItemBar } from '@mui/material';
import { useListContext, ReferenceField, TextField, ImageField } from 'react-admin';

const GridList = ({setEditModalOpen, setCreateModalOpen, setSelectedRecord, setCreateModeString, adminMode}) => {
    const { isLoading } = useListContext();
    return isLoading ? <LoadingGridList /> : <LoadedGridList setEditModalOpen={setEditModalOpen} setCreateModalOpen={setCreateModalOpen} setSelectedRecord={setSelectedRecord} setCreateModeString={setCreateModeString} adminMode={adminMode} />;
};

const useColsForWidth = () => {
    const theme = useTheme();
    const sm = useMediaQuery(theme.breakpoints.up('sm'));
    const md = useMediaQuery(theme.breakpoints.up('md'));
    const lg = useMediaQuery(theme.breakpoints.up('lg'));
    const xl = useMediaQuery(theme.breakpoints.up('xl'));
    // there are all dividers of 24, to have full rows on each page
    if (xl) return 4;
    if (lg) return 3;
    if (md) return 2;
    if (sm) return 1;
    return 2;
};

const times = (nbChildren: number, fn: (key: number) => any) =>
    Array.from({ length: nbChildren }, (_, key) => fn(key));

const LoadingGridList = () => {
    const { perPage } = useListContext();
    const cols = useColsForWidth();
    return (
        <ImageList rowHeight={180} cols={cols} sx={{ m: 0 }}>
            {times(perPage, key => (
                <ImageListItem key={key}>
                    <Box bgcolor="grey.300" height="100%" />
                </ImageListItem>
            ))}
        </ImageList>
    );
};

const LoadedGridList = ({setEditModalOpen, setCreateModalOpen, setSelectedRecord, setCreateModeString, adminMode}) => {
    const { data } = useListContext();
    const cols = useColsForWidth();

    if (!data) return null;

    return (
        <ImageList gap={16} cols={cols} sx={{ m: 0 }}>
            {data.map(record => (
                // <div
                //     key={record.id}
                //     onClick={() => {
                //         if ((!adminMode && record.created_by === localStorage.getItem('selectedPractice')) || (adminMode && record.created_by === "EMBODI" && localStorage.getItem('isAdmin') === 'true')) {
                //             setSelectedRecord(record)
                //             setEditModalOpen(true)
                //         } else {
                //             // copy & customize
                //             setCreateModeString("Copy & Customize")
                //             setSelectedRecord(record)
                //             setCreateModalOpen(true)
                //         }
                //     }}
                //     style={{
                //         cursor: 'pointer',
                //     }}
                // >
                    <ImageListItem
                        sx={{
                            borderRadius: '8px',
                            border: '1px solid #e0e0e0',
                            overflow: 'hidden',
                            boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.1)',
                            position: 'relative',
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            if ((!adminMode && record.created_by === localStorage.getItem('selectedPractice')) || (adminMode && record.created_by === "EMBODI" && localStorage.getItem('isAdmin') === 'true')) {
                                setSelectedRecord(record)
                                setEditModalOpen(true)
                            } else {
                                // copy & customize
                                setCreateModeString("Copy & Customize")
                                setSelectedRecord(record)
                                setCreateModalOpen(true)
                            }
                        }}
                    >
                        {record.condition_id ? (
                            <ReferenceField
                                record={record}
                                source="condition_id"
                                reference='conditions'
                                link={false}
                            >
                                <ImageField
                                    source="image"
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '100%',
                                        width: '100%',
                                        '& img': {
                                            height: '100%',
                                            width: '100%',
                                            objectFit: 'contain !important',
                                        }
                                    }}
                                />
                            </ReferenceField>
                        ) : (
                            <ImageField
                                record={record}
                                source="image"
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: '100%',
                                    width: '100%',
                                    '& img': {
                                        height: '100%',
                                        width: '100%',
                                        objectFit: 'contain !important',
                                    }
                                }}
                            />
                        )}
                        <img src={record.thumbnail} alt="" />
                        <div
                            style={{
                                position: 'absolute',
                                minWidth: '80px',
                                top: '0px',
                                right: '0px',
                                backgroundColor: '#0000d2',
                                color: 'white',
                                padding: '4px 8px',
                                borderRadius: '4px',
                                fontSize: '1rem',
                                fontWeight: 'bold',
                                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            {((!adminMode && record.created_by === localStorage.getItem('selectedPractice')) || (adminMode && record.created_by === "EMBODI" && localStorage.getItem('isAdmin') === 'true')) ? "Edit" : "Copy & Customize"}
                        </div>
                        <ImageListItemBar
                            sx={{
                                paddingLeft: '1rem',
                                paddingRight: '1rem',
                                paddingTop: '0.25rem',
                                paddingBottom: '0.25rem',
                            }}
                            position="below"
                            title={(
                                <span style={{ color: 'inherit', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                    {record.name}
                                </span>
                            )}
                            subtitle={record.created_by === "EMBODI" ? 
                                (
                                    <div style={{ paddingBottom: '2px', paddingTop: '3px' }}>
                                        Created by: EMBODI
                                    </div>
                                ) : (
                                <ReferenceField
                                    record={record}
                                    source="created_by"
                                    reference='practices'
                                >
                                    Created by: <TextField sx={{ fontSize: 'inherit' }} source="name" />
                                </ReferenceField>
                            )}
                        />
                    </ImageListItem>
                // </div>
            ))}
        </ImageList>
    );
};

export default GridList;
