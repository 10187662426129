import { AppBar, Logout, UserMenu } from 'react-admin';
import {
    MenuItem,
    useMediaQuery,
    Theme,
    Select,
    IconButton
} from '@mui/material';

import Logo from './Logo';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { useEffect, useMemo, useRef, useState } from 'react';
import {useLocation} from 'react-router-dom';

import '../scss/AppBar.scss';

const CustomUserMenu = () => {
    return (
      <UserMenu>
          <Logout />
      </UserMenu>
)};

const ProviderMenu = (props: any) => {

  // console.log("props", props)

  const location = useLocation();

  const urlParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const [selectedPractice, setSelectedPractice] = useState(() => urlParams.get("practice_id"))

  useEffect(() => {
    const practice_id = urlParams.get("practice_id")
    if (practice_id && practice_id !== selectedPractice) {
      setSelectedPractice(practice_id)
    }
  }, [urlParams, selectedPractice])

  useEffect(() => {
    if (selectedPractice !== null && selectedPractice !== localStorage.getItem("selectedPractice")) {
      props.handlePracticeChangeParams(selectedPractice);
    }
  }, [selectedPractice]);


    return (
        <div id="provider-selector">
            <Select
                labelId="user-providers"
                value={ selectedPractice? selectedPractice : props.currpractice}
                label="Practice"
                onChange={props.handlepracticechange}
                sx={{ borderColor: 'red' }}
            >
                {
                    props.practices.map((practice, i) => {
                        if(typeof practice['name'] === 'string' && practice['status'] === 'active') return (<MenuItem key={i} value={practice['id']}>{practice['name']}</MenuItem>) 
                    })
                }
            </Select>
        </div>
    )
}

const CustomAppBar = (props: any) => {

    const [isSupportMenuOpen, setIsSupportMenuOpen] = useState(false)
    const toggleSupportMenu = () => setIsSupportMenuOpen(!isSupportMenuOpen)

    function useOutsideAlerter(ref) {
      useEffect(() => {
        function handleClickOutside(event) {
          if (ref.current && !ref.current.contains(event.target)) {
            setIsSupportMenuOpen(false)
          }
        }
    
        document.addEventListener("mousedown", handleClickOutside)
    
        return () => {
          document.removeEventListener("mousedown", handleClickOutside)
        }
      }, [ref])
    }

    const wrapperRef = useRef(null)
    useOutsideAlerter(wrapperRef)

    const isLargeEnough = useMediaQuery<Theme>(theme =>
        theme.breakpoints.up('sm')
    );

    const SupportButton = () => {
      return (<IconButton className='support-menu-toggle' color="inherit" onClick={toggleSupportMenu}>
          <HelpOutlineOutlinedIcon />
      </IconButton>)
    };

    return (
        <div className='app-bar-container'>
            <AppBar
                {...props}
                className='app-bar'
                color="default"
                elevation={1}
                userMenu={<CustomUserMenu />}
            >
                {isLargeEnough && <Logo />}
                <div>&nbsp;{ process.env.REACT_APP_RELEASE } <span>{ process.env.REACT_APP_ENV }</span></div>
                <ProviderMenu practices={props.practices} currpractice={props.currpractice} handlepracticechange={props.handlepracticechange} handlePracticeChangeParams={props.handlePracticeChangeParams} />
                <SupportButton />
            </AppBar>
            {isSupportMenuOpen ? <div className='support-menu' ref={wrapperRef}>
              <div className='support-menu-item' onClick={toggleSupportMenu}>
                <a href="https://kaizenovate.zendesk.com/hc/en-us" target="_blank" className='support-menu-link'>
                  Help Center
                </a>
              </div>
              <div className='support-menu-item' onClick={toggleSupportMenu}>
                <a href="https://kaizenovate.zendesk.com/hc/en-us/requests/new" target="_blank" className='support-menu-link'>
                  Contact Support
                </a>
              </div>
            </div> : null}
        </div>
    );
};

export default CustomAppBar;
