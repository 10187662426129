import * as React from 'react';
import { 
    Create, 
    TextInput,  
    SimpleForm ,
    SelectInput,
    ReferenceInput,
} from 'react-admin';
import { Box, Typography } from '@mui/material';


const ConditionsCreate = () => {
    return (
        <Create>
            <SimpleForm
                sx={{ width: '80%' }}
            >
                <Typography variant="h4" gutterBottom mb={3}>
                    <Box fontWeight='fontWeightBold'>
                        Create Condition
                    </Box>
                </Typography>

                <TextInput
                    autoFocus
                    source="name"
                    isRequired
                    fullWidth
                />
                <TextInput
                    source="diagnosis_codes"
                    fullWidth
                    isRequired
                />
                <TextInput
                    source="image"
                    isRequired
                    fullWidth
                />
                <Box flex={6} mr={{ xs: 0, sm: '0.5em' }}>
                    <ReferenceInput
                        source="masterprogram_id"
                        reference="masterprograms"
                        filter={{ practice_id: "EMBODI" }}
                        sort={{ field: 'name', order: 'ASC' }}
                    >
                        <SelectInput optionValue="id" optionText="name" label="Protocol" source="name" fullWidth={false} />
                    </ReferenceInput>
                </Box>
            </SimpleForm>
        </Create>
    );
};

export default ConditionsCreate;
