import * as React from 'react';
import { Link } from 'react-router-dom';
import { Link as MuiLink } from '@mui/material';

const ExerciseRefField = ({record, isStaticExercise, setEditStaticOpenState, setEditProgressiveOpenState, setEditRecord}) => {
    return record ? (
        //isStaticExercise ? (
            <MuiLink
                onClick={() => {
                    setEditRecord(record);
                    if (isStaticExercise) {
                        setEditStaticOpenState(true)
                    } else {
                        setEditProgressiveOpenState(true)
                    }
                }}
                underline='none'
                style={{cursor: 'pointer'}}
            >
                {/* {record.patient_id} */}
                EDIT
            </MuiLink>
        // ) : (
        //     <MuiLink
        //         component={Link}
        //         to={`/exercises/${record.id}`}
        //         underline="none"
        //         state={{ "id" : record.patient_id }}
        //     >
        //         {/* {record.patient_id} */}
        //         EDIT
        //     </MuiLink>
        // )
    ) : null;
};

export default ExerciseRefField;
